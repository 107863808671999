import _cloneDeep from "lodash/cloneDeep";
import { LOAD_CONFIG } from '../../actionTypes/componentConfig';
import { initialComponentConfigStates } from '../../reducers/componentConfig/initialState';
var loadComponentConfig = function loadComponentConfig(experimentId, plotUuid, type) {
  return function (dispatch) {
    dispatch({
      type: LOAD_CONFIG,
      payload: {
        experimentId: experimentId,
        plotUuid: plotUuid,
        type: type,
        config: _cloneDeep(initialComponentConfigStates[type])
      }
    });
  };
};
export default loadComponentConfig;