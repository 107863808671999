import _isEqual from "lodash/isEqual";
import { createSelector } from 'reselect';
import memoize from 'lru-memoize';
// based on https://www.aarongreenwald.com/blog/redux-reselect-parameters
/**
 *
 * @param {*} selector The selector to memoize
 * @param {*} options an object with different configuration options:
 *        - inputSelectors: The selectors that prepare the input the selector will take,
 *          look at reselect's input selectors for more information:
 *          https://redux.js.org/usage/deriving-data-selectors#writing-memoized-selectors-with-reselect
 *          (identity by default)
 *        - maxCachedKeys: The amount of concurrent memoized selectors of this type
 *          with different parameters supported (4 by default)
 *        - comparisonOperator: The comparison operator to determine selector's keys,
 *          (_.isEqual by default)
 * @returns a memoized by params selector that also uses reselect
 */
var createMemoizedSelector = function createMemoizedSelector(selector) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$maxCachedKey = options.maxCachedKeys,
    maxCachedKeys = _options$maxCachedKey === void 0 ? 4 : _options$maxCachedKey,
    _options$inputSelecto = options.inputSelectors,
    inputSelectors = _options$inputSelecto === void 0 ? [function (state) {
      return state;
    }] : _options$inputSelecto,
    _options$comparisonOp = options.comparisonOperator,
    comparisonOperator = _options$comparisonOp === void 0 ? _isEqual : _options$comparisonOp;
  var makerFunction = function makerFunction() {
    return createSelector(inputSelectors, selector.apply(void 0, arguments));
  };
  return memoize(maxCachedKeys, comparisonOperator)(makerFunction);
};
export default createMemoizedSelector;