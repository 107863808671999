import "antd/lib/alert/style";
import _Alert from "antd/lib/alert";
var __jsx = React.createElement;
import "core-js/modules/es6.regexp.match.js";
import React from 'react';
var BrowserAlert = function BrowserAlert() {
  var renderContent = function renderContent() {
    if (navigator.userAgent.match('Chrome')) return __jsx(React.Fragment, null);
    return __jsx(_Alert, {
      showIcon: true,
      message: __jsx(React.Fragment, null, __jsx("b", null, "Browser not supported."), ' ', "We recommend switching to Google Chrome for the best Cellenics experience."),
      banner: true,
      closable: true,
      style: {
        position: 'fixed',
        top: 0,
        zIndex: 100,
        width: '100%',
        borderBottom: '1px solid #FFF0A3'
      }
    });
  };
  return renderContent();
};
export default BrowserAlert;