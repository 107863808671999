import { EXPERIMENT_SETTINGS_SET_QC_STEP_ENABLED } from 'redux/actionTypes/experimentSettings';
var setQCStepEnabled = function setQCStepEnabled(step, enabled) {
  return function (dispatch) {
    dispatch({
      type: EXPERIMENT_SETTINGS_SET_QC_STEP_ENABLED,
      payload: {
        step: step,
        enabled: enabled
      }
    });
  };
};
export default setQCStepEnabled;