import initialState from './initialState';
import { EMBEDDINGS_LOADED, EMBEDDINGS_LOADING, EMBEDDINGS_ERROR } from '../../actionTypes/embeddings';
import { EXPERIMENT_SETTINGS_QC_START } from '../../actionTypes/experimentSettings';
import embeddingsLoading from './embeddingsLoading';
import embeddingsLoaded from './embeddingsLoaded';
import embeddingsError from './embeddingsError';
var notificationsReducer = function notificationsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case EMBEDDINGS_LOADING:
      {
        return embeddingsLoading(state, action);
      }
    case EMBEDDINGS_LOADED:
      {
        return embeddingsLoaded(state, action);
      }
    case EMBEDDINGS_ERROR:
      {
        return embeddingsError(state, action);
      }
    case EXPERIMENT_SETTINGS_QC_START:
      {
        return initialState;
      }
    default:
      {
        return state;
      }
  }
};
export default notificationsReducer;