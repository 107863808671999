import "antd/lib/message/style";
import _message from "antd/lib/message";
var pushNotificationMessage = function pushNotificationMessage(type, text) {
  var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 4;
  switch (type) {
    case 'success':
      _message.success(text, duration);
      break;
    case 'error':
      _message.error(text, duration);
      break;
    case 'info':
      _message.info(text, duration);
      break;
    case 'warning':
    case 'warn':
      _message.warn(text, duration);
      break;
    case 'loading':
      _message.loading(text, duration);
      break;
    default:
      _message.info(text, duration);
      break;
  }
};
export default pushNotificationMessage;