/**
 * Sets plot configs and data.
 */
var COMPONENT_CONFIG = 'componentConfig';
var LOAD_CONFIG = "".concat(COMPONENT_CONFIG, "/load");
var UPDATE_CONFIG = "".concat(COMPONENT_CONFIG, "/update");
var RESET_CONFIG = "".concat(COMPONENT_CONFIG, "/reset");
var SAVE_CONFIG = "".concat(COMPONENT_CONFIG, "/save");
var CONFIGS_REPLACED = "".concat(COMPONENT_CONFIG, "/replacements");
var PLOT_DATA_LOADED = "".concat(COMPONENT_CONFIG, "/plotDataLoaded");
var PLOT_DATA_LOADING = "".concat(COMPONENT_CONFIG, "/plotDataLoading");
var PLOT_DATA_ERROR = "".concat(COMPONENT_CONFIG, "/plotDataError");
var TRAJECTORY_NODES_SELECTION_UPDATED = "".concat(COMPONENT_CONFIG, "/trajectoryNodesUpdated");
export { LOAD_CONFIG, UPDATE_CONFIG, CONFIGS_REPLACED, RESET_CONFIG, SAVE_CONFIG, PLOT_DATA_LOADED, PLOT_DATA_LOADING, PLOT_DATA_ERROR, TRAJECTORY_NODES_SELECTION_UPDATED };