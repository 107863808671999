import ExpressionMatrix from 'utils/ExpressionMatrix/ExpressionMatrix';
var initialViewState = {
  fetching: false,
  error: false,
  data: []
};
var getInitialState = function getInitialState() {
  return {
    properties: {
      loading: [],
      views: {},
      data: {}
    },
    expression: {
      views: {},
      full: {
        loading: [],
        error: false,
        matrix: new ExpressionMatrix()
      },
      downsampled: {
        loading: [],
        error: false,
        matrix: new ExpressionMatrix(),
        cellOrder: [],
        ETag: null
      }
    },
    selected: [],
    focused: undefined,
    markers: {
      loading: false,
      error: false,
      ETag: null
    }
  };
};
export { initialViewState };
export default getInitialState;