import { EXPERIMENT_SETTINGS_SET_SAMPLE_FILTER_SETTINGS_AUTO } from '../../../actionTypes/experimentSettings';
var setSampleFilterSettingsAuto = function setSampleFilterSettingsAuto(step, sampleId, isAuto) {
  return function (dispatch) {
    dispatch({
      type: EXPERIMENT_SETTINGS_SET_SAMPLE_FILTER_SETTINGS_AUTO,
      payload: {
        step: step,
        sampleId: sampleId,
        isAuto: isAuto
      }
    });
  };
};
export default setSampleFilterSettingsAuto;