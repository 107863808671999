var GENES = 'genes';

/**
 * Turns on the loading condition for gene information.
 * Components that have the particular properties requested in their dependencies
 * should set a loading state at this event.
 */
var GENES_PROPERTIES_LOADING = "".concat(GENES, "/propertiesLoading");

/**
 * Sets the state of the gene data to be successfully loaded, with the appropriate gene data.
 * This is meant to be used for paginated data, where the data is
 * lazy-loaded by an API call or equivalent.
 */
var GENES_PROPERTIES_LOADED_PAGINATED = "".concat(GENES, "/propertiesLoadedPaginated");

/**
 * Sets an error condition for gene properties data.
 */
var GENES_PROPERTIES_ERROR = "".concat(GENES, "/propertiesError");

/**
 * Updates the list of selected genes with a new selected gene.
 */
var GENES_SELECT = "".concat(GENES, "/select");

/**
 * Removes a deselected gene from the list of selected genes.
 */
var GENES_DESELECT = "".concat(GENES, "/deselect");

/**
 * Turns on the loading state for expression data.
 */
var GENES_EXPRESSION_LOADING = "".concat(GENES, "/expressionLoading");

/**
 * Sets the state of the expression store to be successfully loaded,
 * with the appropriate expression data.
 */
var GENES_EXPRESSION_LOADED = "".concat(GENES, "/expressionLoaded");

/**
 * Sets an error condition for gene expression data.
 */
var GENES_EXPRESSION_ERROR = "".concat(GENES, "/expressionError");

/**
 * Sets marker genes as loading
 */
var MARKER_GENES_LOADING = "".concat(GENES, "/markerGenesLoading");

/**
 * Marker genes have finished loading
 */
var MARKER_GENES_LOADED = "".concat(GENES, "/markerGenesLoaded");

/**
 * Marker genes load task had some kind of error
 */
var MARKER_GENES_ERROR = "".concat(GENES, "/markerGenesError");

/**
 * Downsampled genes expression load task
 */
var DOWNSAMPLED_GENES_EXPRESSION_LOADING = "".concat(GENES, "/downsampledGenesExpressionLoading");

/**
 * Downsampled genes expression loaded
 */
var DOWNSAMPLED_GENES_EXPRESSION_LOADED = "".concat(GENES, "/downsampledGenesExpressionLoaded");

/**
 * Downsampled genes expression loaded
 */
var DOWNSAMPLED_GENES_EXPRESSION_ERROR = "".concat(GENES, "/downsampledGenesExpressionError");
export { GENES_PROPERTIES_LOADING, GENES_PROPERTIES_LOADED_PAGINATED, GENES_PROPERTIES_ERROR, GENES_SELECT, GENES_DESELECT, MARKER_GENES_LOADING, MARKER_GENES_LOADED, MARKER_GENES_ERROR, GENES_EXPRESSION_LOADING, GENES_EXPRESSION_LOADED, GENES_EXPRESSION_ERROR, DOWNSAMPLED_GENES_EXPRESSION_LOADING, DOWNSAMPLED_GENES_EXPRESSION_LOADED, DOWNSAMPLED_GENES_EXPRESSION_ERROR };