var mitochondrialContent = {
  loading: true,
  error: false,
  data: []
};
var doubletScores = {
  loading: true,
  error: false,
  data: []
};
var numOfGenes = {
  loading: true,
  error: false,
  data: []
};
var numOfUmis = {
  loading: true,
  error: false,
  data: []
};
var initialState = {
  mitochondrialContent: mitochondrialContent,
  doubletScores: doubletScores,
  numOfGenes: numOfGenes,
  numOfUmis: numOfUmis
};
export default initialState;