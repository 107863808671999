import { EXPERIMENT_SETTINGS_COPY_SETTINGS_TO_ALL_SAMPLES } from '../../../actionTypes/experimentSettings';
var copyFilterSettingsToAllSamples = function copyFilterSettingsToAllSamples(step, sourceSampleId, sampleIds) {
  return function (dispatch) {
    dispatch({
      type: EXPERIMENT_SETTINGS_COPY_SETTINGS_TO_ALL_SAMPLES,
      payload: {
        step: step,
        sourceSampleId: sourceSampleId,
        sampleIds: sampleIds
      }
    });
  };
};
export default copyFilterSettingsToAllSamples;