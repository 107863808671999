import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
var getAllCells = function getAllCells(cellSets) {
  var _cellSets$hierarchy$f;
  var groupBy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'sample';
  // We are using sample as cellSetClass to iterate by default
  // because heuristically it has the least number of entries
  var sampleHierarchy = ((_cellSets$hierarchy$f = cellSets.hierarchy.find(function (rootNode) {
    return rootNode.key === groupBy;
  })) === null || _cellSets$hierarchy$f === void 0 ? void 0 : _cellSets$hierarchy$f.children) || [];
  var sampleKeys = sampleHierarchy.map(function (sample) {
    return sample.key;
  });
  var allCells = sampleKeys.reduce(function (acc, sampleKey) {
    var cellIdsArr = Array.from(cellSets.properties[sampleKey].cellIds);
    var sampleCellId = cellIdsArr.map(function (cellId) {
      return {
        cellId: cellId,
        cellSetKey: sampleKey
      };
    });
    return acc.concat(sampleCellId);
  }, []);
  return allCells;
};
export default getAllCells;