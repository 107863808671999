/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var environmentHydrate = produce(function (draft, action) {
  var _action$payload$netwo = action.payload.networkResources,
    environment = _action$payload$netwo.environment,
    domainName = _action$payload$netwo.domainName;
  draft.environment = environment;
  draft.domainName = domainName;
}, initialState);
export default environmentHydrate;