var EXPERIMENT_SETTINGS = 'experimentSettings';

/**
 * Loaded general information about the experiment.
 */
var EXPERIMENT_SETTINGS_INFO_UPDATE = "".concat(EXPERIMENT_SETTINGS, "/updateInfo");

/**
 * Sets the state of the processing component to a state displayable by the system.
 */
var EXPERIMENT_SETTINGS_PROCESSING_CONFIG_LOADED = "".concat(EXPERIMENT_SETTINGS, "/loadedProcessingConfig");

/**
 * Sets error value in store if there is error in loading
 */
var EXPERIMENT_SETTINGS_PROCESSING_ERROR = "".concat(EXPERIMENT_SETTINGS, "/errorProcessing");

/**
 * Updates the processing component's configuration.
 */
var EXPERIMENT_SETTINGS_NON_SAMPLE_FILTER_UPDATE = "".concat(EXPERIMENT_SETTINGS, "/updateNonSampleFilterSettings");

/**
 * Updates the processing component's configuration.
 */
var EXPERIMENT_SETTINGS_SAMPLE_FILTER_UPDATE = "".concat(EXPERIMENT_SETTINGS, "/updateSampleFilterSettings");

/**
 * Saves the current state of the configuration to DynamoDB.
 */
var EXPERIMENT_SETTINGS_PROCESSING_SAVE = "".concat(EXPERIMENT_SETTINGS, "/saveProcessing");

/**
 * Signals that a pipeline run was initiated by the user.
 */
var EXPERIMENT_SETTINGS_QC_START = "".concat(EXPERIMENT_SETTINGS, "/startQC");

/**
 * A QC step was enabled or disabled
 */
var EXPERIMENT_SETTINGS_SET_QC_STEP_ENABLED = "".concat(EXPERIMENT_SETTINGS, "/setQCStepEnabled");

/**
 * The filter settings for one sample in one step were copied over to all other samples
 */
var EXPERIMENT_SETTINGS_COPY_SETTINGS_TO_ALL_SAMPLES = "".concat(EXPERIMENT_SETTINGS, "/copyFilterSettingsToAllSamples");
var EXPERIMENT_SETTINGS_SET_SAMPLE_FILTER_SETTINGS_AUTO = "".concat(EXPERIMENT_SETTINGS, "/setSampleFilterSettingsAuto");

/**
 * The filter settings for a filter were changed
 */
var EXPERIMENT_SETTINGS_ADD_CHANGED_QC_FILTER = "".concat(EXPERIMENT_SETTINGS, "/addChangedQCFilter");

/**
 * The pending settings for filters were discarded
 */
var EXPERIMENT_SETTINGS_DISCARD_CHANGED_QC_FILTERS = "".concat(EXPERIMENT_SETTINGS, "/discardChangedQCFilters");

/**
 * Received an update from qc for a sample in a step
 */
var EXPERIMENT_SETTINGS_UPDATE_SAMPLE_FROM_QC = "".concat(EXPERIMENT_SETTINGS, "/updateSampleProcessingSettingsFromQC");
var EXPERIMENT_SETTINGS_PIPELINE_VERSION_UPDATED = "".concat(EXPERIMENT_SETTINGS, "/pipelineVersionUpdated");
export { EXPERIMENT_SETTINGS_INFO_UPDATE, EXPERIMENT_SETTINGS_PROCESSING_CONFIG_LOADED, EXPERIMENT_SETTINGS_NON_SAMPLE_FILTER_UPDATE, EXPERIMENT_SETTINGS_SAMPLE_FILTER_UPDATE, EXPERIMENT_SETTINGS_PROCESSING_SAVE, EXPERIMENT_SETTINGS_PROCESSING_ERROR, EXPERIMENT_SETTINGS_QC_START, EXPERIMENT_SETTINGS_SET_QC_STEP_ENABLED, EXPERIMENT_SETTINGS_COPY_SETTINGS_TO_ALL_SAMPLES, EXPERIMENT_SETTINGS_SET_SAMPLE_FILTER_SETTINGS_AUTO, EXPERIMENT_SETTINGS_ADD_CHANGED_QC_FILTER, EXPERIMENT_SETTINGS_DISCARD_CHANGED_QC_FILTERS, EXPERIMENT_SETTINGS_UPDATE_SAMPLE_FROM_QC, EXPERIMENT_SETTINGS_PIPELINE_VERSION_UPDATED };