var SAMPLES = 'samples';

/**
 * Samples created.
 */
var SAMPLES_CREATED = "".concat(SAMPLES, "/created");

/**
 * Update sample.
 */
var SAMPLES_UPDATE = "".concat(SAMPLES, "/update");

/**
 * Delete sample.
 */
var SAMPLES_DELETE = "".concat(SAMPLES, "/delete");

/**
 * Update files in sample.
 */
var SAMPLES_FILE_UPDATE = "".concat(SAMPLES, "/fileUpdate");

/**
 * Bulk update sample options.
 */
var SAMPLES_OPTIONS_UPDATE = "".concat(SAMPLES, "/optionsUpdate");

/**
 * Load sample.
 */
var SAMPLES_LOADED = "".concat(SAMPLES, "/loaded");

/**
 * Saving samples.
 */
var SAMPLES_SAVING = "".concat(SAMPLES, "/saving");

/**
 * Samples saved successfully.
 */
var SAMPLES_SAVED = "".concat(SAMPLES, "/saved");

/**
 * Error loading/saving sample.
 */
var SAMPLES_ERROR = "".concat(SAMPLES, "/error");

/**
 * Delete metada from sample.
 */
var SAMPLES_METADATA_DELETE = "".concat(SAMPLES, "/metadataDelete");

/**
 * Value in a metadata track was updated for a sample.
 */
var SAMPLES_VALUE_IN_METADATA_TRACK_UPDATED = "".concat(SAMPLES, "/valueInMetadataTrackUpdated");
var SAMPLES_LOADING = "".concat(SAMPLES, "/loading");
var SAMPLES_VALIDATING_UPDATED = "".concat(SAMPLES, "/validatingUpdated");
export { SAMPLES_CREATED, SAMPLES_UPDATE, SAMPLES_DELETE, SAMPLES_FILE_UPDATE, SAMPLES_OPTIONS_UPDATE, SAMPLES_LOADED, SAMPLES_SAVING, SAMPLES_ERROR, SAMPLES_SAVED, SAMPLES_METADATA_DELETE, SAMPLES_VALUE_IN_METADATA_TRACK_UPDATED, SAMPLES_LOADING, SAMPLES_VALIDATING_UPDATED };