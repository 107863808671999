import { Environment, ssrGetDeploymentInfo } from 'utils/deploymentInfo';
import postErrorToSlack from 'utils/postErrorToSlack';
import pushNotificationMessage from 'utils/pushNotificationMessage';
import endUserMessages from 'utils/endUserMessages';
import httpStatusCodes from 'utils/http/httpStatusCodes';
var _ssrGetDeploymentInfo = ssrGetDeploymentInfo(),
  environment = _ssrGetDeploymentInfo.environment;
var handleCodedErrors = function handleCodedErrors(error) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var notifyUser = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var errorMessage = message !== null && message !== void 0 ? message : error.message;

  // let errorMessage = [message, error.userMessage].filter((x) => x).join(' ');

  // We might want to override the API message for some errors to make them
  // more user friendly like the FORBIDDEN no permissions.
  switch (error.statusCode) {
    case httpStatusCodes.FORBIDDEN:
      errorMessage = endUserMessages.ERROR_NO_PERMISSIONS;
      break;
    default:
  }
  if (notifyUser) {
    pushNotificationMessage('error', errorMessage);
  }
  return errorMessage;
};
var handleGenericErrors = function handleGenericErrors(error, message, notifyUser) {
  var errorMessage = message !== null && message !== void 0 ? message : error.message;
  if (notifyUser) {
    pushNotificationMessage('error', "".concat(message));
  }
  if (environment === Environment.PRODUCTION) {
    // add the intended user message to the error to know where
    // the error comes from
    if (message) {
      // eslint-disable-next-line no-param-reassign
      error.message += message;
    }
    postErrorToSlack(error);
  }
  return errorMessage;
};
var handleError = function handleError(error, message) {
  var notifyUser = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var errorMessage;
  if (error && error.statusCode) {
    errorMessage = handleCodedErrors(error, message, notifyUser);
  } else {
    errorMessage = handleGenericErrors(error, message, notifyUser);
  }
  return errorMessage;
};
export default handleError;