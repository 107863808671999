/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var backendStatusLoaded = produce(function (draft, action) {
  var _action$payload = action.payload,
    experimentId = _action$payload.experimentId,
    status = _action$payload.status;
  draft[experimentId] = {
    status: status,
    loading: false,
    error: false
  };
}, initialState);
export default backendStatusLoaded;