import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
var getPlotConfigs = function getPlotConfigs(plotUuids) {
  return function (state) {
    if (!plotUuids) return {};
    var plotConfigsToReturn = plotUuids.reduce(function (acum, plotUuid) {
      var _state$plotUuid;
      /* eslint-disable-next-line no-param-reassign */
      acum[plotUuid] = (_state$plotUuid = state[plotUuid]) === null || _state$plotUuid === void 0 ? void 0 : _state$plotUuid.config;
      return acum;
    }, {});
    return plotConfigsToReturn;
  };
};
export default createMemoizedSelector(getPlotConfigs);