var DIFF_EXPR = 'diffExpr';

/**
 * Turns on the loading condition for differential expression.
 * Components that have the particular properties requested in their dependencies
 * should set a loading state at this event.
 */
var DIFF_EXPR_LOADING = "".concat(DIFF_EXPR, "/loading");

/**
 * Sets the state of the differential expression data to be successfully loaded.
 * This is meant to be used for paginated data, where the data is
 * lazy-loaded by an API call or equivalent.
 */
var DIFF_EXPR_LOADED = "".concat(DIFF_EXPR, "/loaded");
var DIFF_EXPR_ERROR = "".concat(DIFF_EXPR, "/error");

/**
 * Sets the state for diffential expression settings
 *
 */
var DIFF_EXPR_COMPARISON_TYPE_SET = "".concat(DIFF_EXPR, "/comparisonType");
var DIFF_EXPR_COMPARISON_GROUP_SET = "".concat(DIFF_EXPR, "/comparisonGroup");
var DIFF_EXPR_ORDERING_SET = "".concat(DIFF_EXPR, "/ordering");
export { DIFF_EXPR_LOADING, DIFF_EXPR_LOADED, DIFF_EXPR_ERROR, DIFF_EXPR_COMPARISON_TYPE_SET, DIFF_EXPR_COMPARISON_GROUP_SET, DIFF_EXPR_ORDERING_SET };