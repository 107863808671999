import _isNil from "lodash/isNil";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import pipelineStatusValues from 'utils/pipelineStatusValues';
var calculateGem2sRerunStatus = function calculateGem2sRerunStatus(gem2sBackendStatus, activeExperiment) {
  var _ref = gem2sBackendStatus !== null && gem2sBackendStatus !== void 0 ? gem2sBackendStatus : {},
    status = _ref.status,
    shouldRerun = _ref.shouldRerun;
  var pipelineSuccessful = [pipelineStatusValues.SUCCEEDED, pipelineStatusValues.RUNNING].includes(status);
  var rerunReasons = [];
  if (!pipelineSuccessful) rerunReasons.push('data has not been processed sucessfully');
  if (shouldRerun) rerunReasons.push('the experiment samples/metadata have been modified');
  return {
    rerun: _isNil(activeExperiment.parentExperimentId) && (!pipelineSuccessful || shouldRerun),
    reasons: rerunReasons,
    complete: status === pipelineStatusValues.SUCCEEDED
  };
};
export default calculateGem2sRerunStatus;