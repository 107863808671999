import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _cloneDeep from "lodash/cloneDeep";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mergeObjectReplacingArrays } from 'utils/arrayUtils';
var updateConfig = function updateConfig(state, action) {
  var _state$plotUuid, _state$plotUuid2;
  var _action$payload = action.payload,
    plotUuid = _action$payload.plotUuid,
    _action$payload$confi = _action$payload.configChanges,
    configChanges = _action$payload$confi === void 0 ? null : _action$payload$confi,
    _action$payload$dataC = _action$payload.dataChanges,
    dataChanges = _action$payload$dataC === void 0 ? null : _action$payload$dataC;
  var newConfig = _cloneDeep((_state$plotUuid = state[plotUuid]) === null || _state$plotUuid === void 0 ? void 0 : _state$plotUuid.config);
  if (configChanges) {
    newConfig = mergeObjectReplacingArrays(newConfig, configChanges);
  }
  var newData = dataChanges !== null && dataChanges !== void 0 ? dataChanges : (_state$plotUuid2 = state[plotUuid]) === null || _state$plotUuid2 === void 0 ? void 0 : _state$plotUuid2.plotData;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, plotUuid, _objectSpread(_objectSpread({}, state[plotUuid]), {}, {
    config: newConfig,
    plotData: newData,
    outstandingChanges: true
  })));
};
export default updateConfig;