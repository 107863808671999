import { UPDATE_CONFIG } from '../../actionTypes/componentConfig';
var updatePlotData = function updatePlotData(plotUuid, dataChanges) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_CONFIG,
      payload: {
        plotUuid: plotUuid,
        dataChanges: dataChanges
      }
    });
  };
};
export default updatePlotData;