var __jsx = React.createElement;
import React from 'react';
import ChangesNotAppliedModal from 'components/data-processing/ChangesNotAppliedModal';
var DataProcessingIntercept = function DataProcessingIntercept(props) {
  var onContinueNavigation = props.onContinueNavigation,
    onCancelNavigation = props.onCancelNavigation,
    onDismissIntercept = props.onDismissIntercept;
  return __jsx(ChangesNotAppliedModal, {
    onRunQC: function onRunQC() {
      onCancelNavigation();
      onDismissIntercept();
    },
    onDiscardChanges: function onDiscardChanges() {
      onDismissIntercept();
      onContinueNavigation();
    },
    onCloseModal: function onCloseModal() {
      onDismissIntercept();
    }
  });
};
DataProcessingIntercept.defaultProps = {
  onContinueNavigation: function onContinueNavigation() {},
  onCancelNavigation: function onCancelNavigation() {},
  onDismissIntercept: function onDismissIntercept() {}
};
export default DataProcessingIntercept;