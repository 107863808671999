import _slicedToArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
/* eslint-disable no-param-reassign */
import produce, { current } from 'immer';
import initialState from '../initialState';
var discardChangedQCFilters = produce(function (draft) {
  var originalProcessing = current(draft.originalProcessing);
  Object.entries(originalProcessing).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    draft.processing[key] = value;
  });
  draft.processing.meta.changedQCFilters = new Set();
}, initialState);
export default discardChangedQCFilters;