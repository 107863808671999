import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var UploadStatus = {
  UPLOADED: 'uploaded',
  UPLOADING: 'uploading',
  COMPRESSING: 'compressing',
  UPLOAD_ERROR: 'uploadError',
  FILE_NOT_FOUND: 'fileNotFound',
  FILE_READ_ERROR: 'fileReadError',
  FILE_READ_ABORTED: 'fileReadAborted'
};
var message = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, UploadStatus.UPLOADED, 'Uploaded'), UploadStatus.UPLOADING, 'Uploading...'), UploadStatus.COMPRESSING, 'Compressing...'), UploadStatus.UPLOAD_ERROR, 'Upload error'), UploadStatus.FILE_NOT_FOUND, 'File not found'), UploadStatus.FILE_READ_ERROR, 'File read error'), UploadStatus.FILE_READ_ABORTED, 'File read aborted');
var messageForStatus = function messageForStatus(uploadStatus) {
  return message[uploadStatus];
};
export { messageForStatus };
export default UploadStatus;