/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from '../initialState';
var processingSettingsError = produce(function (draft, action) {
  var _action$payload = action.payload,
    error = _action$payload.error,
    errorType = _action$payload.errorType;
  draft.processing.meta.loading = false;
  draft.processing.meta[errorType] = error;
}, initialState);
export default processingSettingsError;