import "core-js/modules/es6.regexp.replace.js";
/* eslint-disable no-param-reassign */
var metadataKeyToName = function metadataKeyToName(key) {
  return key.replace('_', ' ');
};
var metadataNameToKey = function metadataNameToKey(name) {
  return "".concat(name.trim().replace(/\s+/g, '_'));
};
var temporaryMetadataKey = function temporaryMetadataKey(columns) {
  return "metadata_".concat(columns.length);
};
export { metadataKeyToName, metadataNameToKey, temporaryMetadataKey };