import _difference from "lodash/difference";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
var downsampledGenesError = produce(function (draft, action) {
  var _action$payload = action.payload,
    error = _action$payload.error,
    componentUuid = _action$payload.componentUuid,
    genes = _action$payload.genes;
  var previousLoadingGenes = original(draft.expression.downsampled.loading);
  draft.expression.views[componentUuid].fetching = false;
  draft.expression.views[componentUuid].error = error;
  draft.expression.downsampled.error = error;
  draft.expression.downsampled.loading = _difference(previousLoadingGenes, genes);
});
export default downsampledGenesError;