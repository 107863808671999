import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
var trajectoryNodesSelectionUpdated = produce(function (draft, action) {
  var _action$payload = action.payload,
    plotUuid = _action$payload.plotUuid,
    nodes = _action$payload.nodes,
    updateAction = _action$payload.action;
  var newNodes;
  var plotConfig = draft[plotUuid].config;
  if (updateAction === 'add') {
    newNodes = _toConsumableArray(new Set([].concat(_toConsumableArray(nodes), _toConsumableArray(original(draft[plotUuid]).config.selectedNodes))));
    plotConfig.selectedNodes = newNodes;
  } else {
    plotConfig.selectedNodes = plotConfig.selectedNodes.filter(function (node) {
      return !nodes.includes(node);
    });
  }
  draft[plotUuid].outstandingChanges = true;
});
export default trajectoryNodesSelectionUpdated;