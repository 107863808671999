var CELL_INFO = 'cellInfo';

/**
 * Update cell information on currently highlighted cell.
 */
var CELL_INFO_UPDATE = "".concat(CELL_INFO, "/update");

/**
 * Set focus on a particular metadata/cell set/gene.
 */
var CELL_INFO_FOCUS = "".concat(CELL_INFO, "/focus");

/**
 * Set focus on a particular cell set or gene.
 */

var CELL_INFO_UNFOCUS = "".concat(CELL_INFO, "/unfocus");
export { CELL_INFO_UPDATE, CELL_INFO_FOCUS, CELL_INFO_UNFOCUS };