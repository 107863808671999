import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _difference from "lodash/difference";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import upperCaseArray from 'utils/upperCaseArray';
import { initialViewState } from './getInitialState';
var genesExpressionLoaded = function genesExpressionLoaded(state, action) {
  var _action$payload = action.payload,
    componentUuid = _action$payload.componentUuid,
    genes = _action$payload.genes,
    _action$payload$loadi = _action$payload.loadingStatus,
    loadingStatus = _action$payload$loadi === void 0 ? _difference(upperCaseArray(state.expression.full.loading), upperCaseArray(genes)) : _action$payload$loadi,
    _action$payload$newGe = _action$payload.newGenes,
    newGenes = _action$payload$newGe === void 0 ? undefined : _action$payload$newGe;

  // If there's any data to load, load it
  if (newGenes) {
    var orderedGeneNames = newGenes.orderedGeneNames,
      rawExpression = newGenes.rawExpression,
      truncatedExpression = newGenes.truncatedExpression,
      zScore = newGenes.zScore,
      stats = newGenes.stats;
    state.expression.full.matrix.pushGeneExpression(orderedGeneNames, rawExpression, truncatedExpression, zScore, stats);
  }
  return _objectSpread(_objectSpread({}, state), {}, {
    expression: _objectSpread(_objectSpread({}, state.expression), {}, {
      views: _objectSpread(_objectSpread({}, state.expression.views), {}, _defineProperty({}, componentUuid, _objectSpread(_objectSpread(_objectSpread({}, initialViewState), state.expression.views[componentUuid]), {}, {
        fetching: false,
        error: false,
        data: genes
      }))),
      full: _objectSpread(_objectSpread({}, state.expression.full), {}, {
        loading: loadingStatus
      })
    })
  });
};
export default genesExpressionLoaded;