import _remove from "lodash/remove";
/* eslint-disable no-param-reassign */
import "core-js/modules/es6.array.find-index.js";
import produce, { current } from 'immer';
var cellSetsDelete = produce(function (draft, action) {
  var key = action.payload.key;
  var parentNodeKey = current(draft).properties[key].parentNodeKey;

  // Remove cellSet from hierarchy
  var cellClassIndex = draft.hierarchy.findIndex(function (rootNode) {
    return rootNode.key === parentNodeKey;
  });
  _remove(draft.hierarchy[cellClassIndex].children, function (_ref) {
    var currentKey = _ref.key;
    return currentKey === key;
  });

  // Delete from the properties as well.
  delete draft.properties[key];

  // If the key was in the list of selected keys, make sure we remove it from there.
  _remove(draft.selected, function (currentSelectedKey) {
    return currentSelectedKey === key;
  });

  // Delete from hidden if it was selected to be hidden.
  draft.hidden.delete(key);
});
export default cellSetsDelete;