import config from 'config';
var decrypt = function decrypt(hash) {
  return decodeURIComponent(window.atob(hash));
};
var getFeedbackWebhookUrl = function getFeedbackWebhookUrl() {
  var feedbackWebhookEndpoint = config.feedbackWebhookEndpoint;
  return decrypt(feedbackWebhookEndpoint);
};
var getReferralWebhookUrl = function getReferralWebhookUrl() {
  var referralWebhookEndpoint = config.referralWebhookEndpoint;
  return decrypt(referralWebhookEndpoint);
};

// Token for Logger bot to post errors as file
// Required becuase webhook doesn't suport file uploads
var getLoggerBotToken = function getLoggerBotToken() {
  var botToken = config.botToken;
  return decrypt(botToken);
};
export { getFeedbackWebhookUrl, getReferralWebhookUrl, getLoggerBotToken };