/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var cellMetaLoaded = produce(function (draft, action) {
  var _action$payload = action.payload,
    metaName = _action$payload.metaName,
    data = _action$payload.data;
  draft[metaName].data = data;
  draft[metaName].loading = false;
  draft[metaName].error = false;
}, initialState);
export default cellMetaLoaded;