import _cloneDeep from "lodash/cloneDeep";
/* eslint-disable no-param-reassign */
import produce, { current } from 'immer';
import { mergeObjectReplacingArrays } from 'utils/arrayUtils';
import initialState from '../initialState';
var updateNonSampleFilterSettings = produce(function (draft, action) {
  var _current$step;
  var _action$payload = action.payload,
    step = _action$payload.step,
    configChange = _action$payload.configChange,
    isALocalChange = _action$payload.isALocalChange;
  if (!step) throw new Error("Invalid step parameter received: ".concat(step));
  var originalProcessingConfig = (_current$step = current(draft.processing)[step]) !== null && _current$step !== void 0 ? _current$step : {};
  var newConfig = _cloneDeep(originalProcessingConfig);
  mergeObjectReplacingArrays(newConfig, configChange);
  draft.processing[step] = newConfig;
  if (!isALocalChange) {
    draft.originalProcessing[step] = newConfig;
  }
}, initialState);
export default updateNonSampleFilterSettings;