import { EXPERIMENT_SETTINGS_ADD_CHANGED_QC_FILTER } from '../../../actionTypes/experimentSettings';
var addChangedQCFilter = function addChangedQCFilter(stepKey) {
  return function (dispatch) {
    dispatch({
      type: EXPERIMENT_SETTINGS_ADD_CHANGED_QC_FILTER,
      payload: {
        stepKey: stepKey
      }
    });
  };
};
export default addChangedQCFilter;