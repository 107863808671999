import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import { removed } from 'utils/arrayUtils';
var samplesValidatingUpdated = function samplesValidatingUpdated(state, action) {
  var _action$payload = action.payload,
    experimentId = _action$payload.experimentId,
    validating = _action$payload.validating;
  var newValidating = _toConsumableArray(state.meta.validating);
  if (validating && !newValidating.includes(experimentId)) {
    newValidating.push(experimentId);
  } else if (!validating) {
    newValidating = removed(experimentId, newValidating);
  }
  return _objectSpread(_objectSpread({}, state), {}, {
    meta: _objectSpread(_objectSpread({}, state.meta), {}, {
      validating: newValidating
    })
  });
};
export default samplesValidatingUpdated;