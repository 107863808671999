import "antd/lib/row/style";
import _Row from "antd/lib/row";
import "antd/lib/col/style";
import _Col from "antd/lib/col";
import "antd/lib/skeleton/style";
import _Skeleton from "antd/lib/skeleton";
var __jsx = React.createElement;
import React from 'react';
var PreloadContent = function PreloadContent() {
  return __jsx(React.Fragment, null, __jsx("div", {
    "data-testid": "preloadContent",
    style: {
      padding: '20px 0'
    }
  }, __jsx(_Row, {
    gutter: 32
  }, __jsx(_Col, {
    className: "gutter-row",
    span: 14,
    offset: 2
  }, __jsx("div", {
    className: "preloadContextSkeleton",
    style: {
      padding: '16px 0px'
    }
  }, __jsx(_Skeleton.Input, {
    style: {
      width: '100%',
      height: 400
    },
    active: true
  })), __jsx("div", {
    className: "preloadContextSkeleton",
    style: {
      padding: '16px 0px'
    }
  }, __jsx(_Skeleton.Input, {
    style: {
      width: '100%',
      height: 400
    },
    active: true
  })), __jsx("div", {
    className: "preloadContextSkeleton",
    style: {
      padding: '16px 0px'
    }
  }, __jsx(_Skeleton.Input, {
    style: {
      width: '100%',
      height: 400
    },
    active: true
  }))), __jsx(_Col, {
    className: "gutter-row",
    span: 6
  }, __jsx(_Skeleton, {
    active: true,
    paragraph: {
      rows: 10
    }
  }), __jsx(_Skeleton, {
    active: true,
    paragraph: {
      rows: 10
    }
  }), __jsx(_Skeleton, {
    active: true,
    paragraph: {
      rows: 10
    }
  })))));
};
export default PreloadContent;