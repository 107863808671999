import _isNil from "lodash/isNil";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
var createHierarchyFromTree = function createHierarchyFromTree(data) {
  return data && data.map(function (rootNode) {
    var rootNodeObject = {
      key: rootNode.key
    };
    if (rootNode.children) {
      rootNodeObject.children = rootNode.children.map(function (child) {
        return {
          key: child.key
        };
      });
    }
    return rootNodeObject;
  });
};
var createPropertiesFromTree = function createPropertiesFromTree(data) {
  // Create object of properties.
  var properties = {};
  var traverseProperties = function traverseProperties(nodes, parentNode) {
    if (nodes) {
      nodes.forEach(function (node) {
        var key = node.key,
          name = node.name,
          color = node.color,
          cellIds = node.cellIds,
          rootNode = node.rootNode,
          type = node.type;
        properties[key] = {
          name: name,
          color: color,
          cellIds: new Set(cellIds),
          rootNode: rootNode,
          type: type
        };
        if (!rootNode && !_isNil(parentNode)) {
          properties[key].parentNodeKey = parentNode.key;
        }
        if (node.children) {
          traverseProperties(node.children, node);
        }
      });
    }
  };
  traverseProperties(data, null);
  return properties;
};
export { createHierarchyFromTree, createPropertiesFromTree };