import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import getCellSetsHierarchy from './getCellSetsHierarchy';
var getCellSetsHierarchyByType = function getCellSetsHierarchyByType(type) {
  var keyExceptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return function (hierarchy) {
    return hierarchy.filter(function (child) {
      return child.type === type && !keyExceptions.includes(child.key);
    });
  };
};
export default createMemoizedSelector(getCellSetsHierarchyByType, {
  inputSelectors: getCellSetsHierarchy()
});