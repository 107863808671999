import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var samplesUpdate = function samplesUpdate(state, action) {
  var _state$sampleUuid;
  var _action$payload = action.payload,
    sampleUuid = _action$payload.sampleUuid,
    sample = _action$payload.sample;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, sampleUuid, _objectSpread(_objectSpread(_objectSpread({}, state[sampleUuid]), sample), {}, {
    metadata: _objectSpread(_objectSpread({}, ((_state$sampleUuid = state[sampleUuid]) === null || _state$sampleUuid === void 0 ? void 0 : _state$sampleUuid.metadata) || {}), (sample === null || sample === void 0 ? void 0 : sample.metadata) || {})
  })));
};
export default samplesUpdate;