import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
var getGeneList = function getGeneList() {
  return function (state) {
    var geneListUuid = 'geneList';
    var list = state.properties.views[geneListUuid];
    return {
      fetching: list === null || list === void 0 ? void 0 : list.fetching,
      error: list === null || list === void 0 ? void 0 : list.error,
      data: state.properties.data
    };
  };
};
export default createMemoizedSelector(getGeneList);