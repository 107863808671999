import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.function.name.js";
import { experimentTemplate } from 'redux/reducers/experiments/initialState';
var experimentCreate = function experimentCreate(state, action) {
  var _action$payload$exper = action.payload.experiment,
    id = _action$payload$exper.id,
    name = _action$payload$exper.name,
    description = _action$payload$exper.description,
    createdAt = _action$payload$exper.createdAt;
  var newExperiment = _objectSpread(_objectSpread({}, experimentTemplate), {}, {
    id: id,
    name: name,
    description: description,
    createdAt: createdAt
  });
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty(_defineProperty({
    ids: [].concat(_toConsumableArray(state.ids), [newExperiment.id])
  }, newExperiment.id, newExperiment), "meta", _objectSpread(_objectSpread({}, state.meta), {}, {
    activeExperimentId: newExperiment.id,
    saving: false
  })));
};
export default experimentCreate;