import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/switch/style";
import _Switch from "antd/lib/switch";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateExperiment, loadExperiments } from 'redux/actions/experiments';
var Text = _Typography.Text;
var NotifyByEmail = function NotifyByEmail(props) {
  var experimentId = props.experimentId;
  var experiment = useSelector(function (state) {
    return state.experiments[experimentId];
  }) || false;
  var dispatch = useDispatch();
  var changeEmailNotification = function changeEmailNotification(value) {
    dispatch(updateExperiment(experimentId, {
      notifyByEmail: value
    }));
  };
  var _ref = useSelector(function (state) {
      var _state$experiments;
      return state === null || state === void 0 || (_state$experiments = state.experiments) === null || _state$experiments === void 0 ? void 0 : _state$experiments.meta;
    }) || false,
    activeExperimentId = _ref.activeExperimentId;
  useEffect(function () {
    if (!activeExperimentId) {
      dispatch(loadExperiments());
    }
  }, []);
  return __jsx(_Space, {
    direction: "horizontal"
  }, __jsx(Text, null, "Get notified about your pipeline status via email  "), __jsx(_Switch, {
    checked: experiment === null || experiment === void 0 ? void 0 : experiment.notifyByEmail,
    onChange: function onChange(value) {
      return changeEmailNotification(value);
    }
  }));
};
export default NotifyByEmail;