import _isNil from "lodash/isNil";
var downloadFromUrl = function downloadFromUrl(url) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  if (!_isNil(filename)) link.download = filename;
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
};
export default downloadFromUrl;