import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.array.map.js";
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var samplesCreated = produce(function (draft, action) {
  var _draft$experimentId$s;
  var _action$payload = action.payload,
    samples = _action$payload.samples,
    experimentId = _action$payload.experimentId;
  (_draft$experimentId$s = draft[experimentId].sampleIds).push.apply(_draft$experimentId$s, _toConsumableArray(samples.map(function (_ref) {
    var uuid = _ref.uuid;
    return uuid;
  })));
}, initialState);
export default samplesCreated;