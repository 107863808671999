import "core-js/modules/es6.array.filter.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import getCellSets from '../cellSets/getCellSets';
var getSelectedMetadataTracks = function getSelectedMetadataTracks(plotUuid) {
  return function (cellSets, componentConfig) {
    var _componentConfig$plot, _selectedTracks$filte;
    if (!cellSets.accessible) return [];
    var selectedTracks = (_componentConfig$plot = componentConfig[plotUuid]) === null || _componentConfig$plot === void 0 ? void 0 : _componentConfig$plot.config.selectedTracks;
    if (!(selectedTracks !== null && selectedTracks !== void 0 && selectedTracks.length)) return [];
    var properties = cellSets.properties;
    var existingSelectedTracks = (_selectedTracks$filte = selectedTracks === null || selectedTracks === void 0 ? void 0 : selectedTracks.filter(function (track) {
      return properties[track];
    })) !== null && _selectedTracks$filte !== void 0 ? _selectedTracks$filte : [];
    return existingSelectedTracks;
  };
};
export default createMemoizedSelector(getSelectedMetadataTracks, {
  inputSelectors: [function (state) {
    return getCellSets()(state.cellSets);
  }, function (state) {
    return state.componentConfig;
  }]
});