import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.replace.js";
import nextConfig from 'next/config';
var getApiEndpoint = function getApiEndpoint(location) {
  try {
    var _nextConfig;
    var _url = new URL(location || window.location.href);
    if (_url.hostname.includes('staging')) {
      return _url.origin.replace('ui', 'api');
    }
    if (_url.hostname.includes('localhost') || _url.hostname.includes('127.0.0.1')) {
      return 'http://localhost:3000';
    }
    var _domainName = (_nextConfig = nextConfig()) === null || _nextConfig === void 0 || (_nextConfig = _nextConfig.publicRuntimeConfig) === null || _nextConfig === void 0 ? void 0 : _nextConfig.domainName;
    return "https://api.".concat(_domainName);
  } catch (error) {
    console.error('Failed to get API endpoint.');
    console.error(error);
  }
};
export default getApiEndpoint;