/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var cellMetaError = produce(function (draft, action) {
  var _action$payload = action.payload,
    metaName = _action$payload.metaName,
    error = _action$payload.error;
  draft[metaName].error = error;
  draft[metaName].loading = false;
}, initialState);
export default cellMetaError;