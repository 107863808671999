import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var differentialExpressionSetGroup = function differentialExpressionSetGroup(state, action) {
  var _action$payload = action.payload,
    cellSet = _action$payload.cellSet,
    basis = _action$payload.basis,
    compareWith = _action$payload.compareWith,
    type = _action$payload.type;
  return _objectSpread(_objectSpread({}, state), {}, {
    comparison: _objectSpread(_objectSpread({}, state.comparison), {}, {
      type: type,
      group: _objectSpread(_objectSpread({}, state.comparison.group), {}, _defineProperty({}, type, {
        cellSet: cellSet,
        compareWith: compareWith,
        basis: basis
      }))
    })
  });
};
export default differentialExpressionSetGroup;