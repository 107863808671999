import _merge from "lodash/merge";
import _cloneDeep from "lodash/cloneDeep";
/* eslint-disable no-param-reassign */
import produce, { current } from 'immer';
import initialState from '../initialState';
var updateSampleFilterSettings = produce(function (draft, action) {
  var _action$payload = action.payload,
    step = _action$payload.step,
    sampleId = _action$payload.sampleId,
    diff = _action$payload.diff;
  var previousSettings = current(draft.processing[step][sampleId].filterSettings);
  var updatedSettings = _cloneDeep(previousSettings);
  _merge(updatedSettings, diff);
  draft.processing[step][sampleId].filterSettings = updatedSettings;
}, initialState);
export default updateSampleFilterSettings;