import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
var metaInitialState = {
  loading: false,
  completingStepError: false,
  loadingSettingsError: false,
  changedQCFilters: new Set()
};
var initialState = {
  info: {
    experimentId: null,
    experimentName: null,
    sampleIds: [],
    pipelineVersion: null
  },
  processing: {
    meta: metaInitialState
  },
  originalProcessing: {}
};
export { metaInitialState };
export default initialState;