var CELL_META = 'cellMeta';

/**
 * Load plot data
 */
var CELL_META_LOADING = "".concat(CELL_META, "/loading");

/**
 * Plot data loaded
 */
var CELL_META_LOADED = "".concat(CELL_META, "/loaded");

/**
 * Plot data error
 */
var CELL_META_ERROR = "".concat(CELL_META, "/error");
export { CELL_META_LOADING, CELL_META_LOADED, CELL_META_ERROR };