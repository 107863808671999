import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _difference from "lodash/difference";
import "core-js/modules/es6.object.keys.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { initialViewState } from './getInitialState';
var genesPropertiesLoadedPaginated = function genesPropertiesLoadedPaginated(state, action) {
  var _action$payload = action.payload,
    properties = _action$payload.properties,
    componentUuid = _action$payload.componentUuid,
    data = _action$payload.data,
    total = _action$payload.total;
  return _objectSpread(_objectSpread({}, state), {}, {
    properties: _objectSpread(_objectSpread({}, state.properties), {}, {
      views: _objectSpread(_objectSpread({}, state.properties.views), {}, _defineProperty({}, componentUuid, _objectSpread(_objectSpread(_objectSpread({}, initialViewState), state.properties.views[componentUuid]), {}, {
        fetching: false,
        error: false,
        data: Object.keys(data),
        total: total
      }))),
      loading: _difference(state.properties.loading, properties),
      data: _objectSpread(_objectSpread({}, state.properties.data), data)
    })
  });
};
export default genesPropertiesLoadedPaginated;