import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "antd/lib/menu/style";
import _Menu from "antd/lib/menu";
import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import "antd/lib/tooltip/style";
import _Tooltip from "antd/lib/tooltip";
import "antd/lib/space/style";
import _Space from "antd/lib/space";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
import "antd/lib/layout/style";
import _Layout from "antd/lib/layout";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5ToTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';
import getDomainSpecificContent from 'utils/getDomainSpecificContent';
import { BuildOutlined, DatabaseOutlined, FolderOpenOutlined, FundViewOutlined } from '@ant-design/icons';
import pipelineErrorUserMessages from 'utils/pipelineErrorUserMessages';
import PrivacyPolicyIntercept from 'components/data-management/PrivacyPolicyIntercept';
import BrowserAlert from 'components/BrowserAlert';
import PreloadContent from 'components/PreloadContent';
import GEM2SLoadingScreen from 'components/GEM2SLoadingScreen';
import PipelineRedirectToDataProcessing from 'components/PipelineRedirectToDataProcessing';
import { getBackendStatus } from 'redux/selectors';
import { loadUser } from 'redux/actions/user';
import { loadBackendStatus } from 'redux/actions/backendStatus';
import { isBrowser, privacyPolicyIsNotAccepted } from 'utils/deploymentInfo';
import { modules, obj2sTechs } from 'utils/constants';
import { useAppRouter } from 'utils/AppRouteProvider';
import experimentUpdatesHandler from 'utils/experimentUpdatesHandler';
import integrationTestConstants from 'utils/integrationTestConstants';
import pipelineStatusValues from 'utils/pipelineStatusValues';
import { DndProvider } from 'react-dnd';
import { loadSamples } from 'redux/actions/samples';
import calculatePipelinesRerunStatus from 'utils/data-management/calculatePipelinesRerunStatus';
var Sider = _Layout.Sider;
var Text = _Typography.Text;
var checkEveryIsValue = function checkEveryIsValue(arr, value) {
  return arr.every(function (item) {
    return item === value;
  });
};
var backendErrors = [pipelineStatusValues.FAILED, pipelineStatusValues.TIMED_OUT, pipelineStatusValues.ABORTED];
var ContentWrapper = function ContentWrapper(props) {
  var _samples$experimentDa, _experimentData$sampl, _backendStatus$pipeli, _backendStatus$gem2s, _backendStatus$gem2s2, _backendStatus$obj2s, _backendStatus$obj2s2;
  var dispatch = useDispatch();
  var _useState = useState(false),
    collapsed = _useState[0],
    setCollapsed = _useState[1];
  var routeExperimentId = props.routeExperimentId,
    experimentData = props.experimentData,
    children = props.children;
  var _useAppRouter = useAppRouter(),
    navigateTo = _useAppRouter.navigateTo,
    currentModule = _useAppRouter.currentModule;
  var currentExperimentIdRef = useRef(routeExperimentId);
  var selectedExperimentID = useSelector(function (state) {
    var _state$experiments;
    return state === null || state === void 0 || (_state$experiments = state.experiments) === null || _state$experiments === void 0 || (_state$experiments = _state$experiments.meta) === null || _state$experiments === void 0 ? void 0 : _state$experiments.activeExperimentId;
  });
  var domainName = useSelector(function (state) {
    return state.networkResources.domainName;
  });
  var user = useSelector(function (state) {
    return state.user.current;
  });
  var samples = useSelector(function (state) {
    return state.samples;
  });
  var selectedTechnology = ((_samples$experimentDa = samples[experimentData === null || experimentData === void 0 || (_experimentData$sampl = experimentData.sampleIds) === null || _experimentData$sampl === void 0 ? void 0 : _experimentData$sampl[0]]) === null || _samples$experimentDa === void 0 ? void 0 : _samples$experimentDa.type) || false;
  useEffect(function () {
    // selectedExperimentID holds the value in redux of the selected experiment
    // after loading a page it is determined whether to use that ID or the ID in the route URL
    // i.e. when we are in data management there is not exp ID in the URL so we get it from redux
    if (!selectedExperimentID && !routeExperimentId) return;
    if (currentModule === modules.DATA_MANAGEMENT) {
      currentExperimentIdRef.current = selectedExperimentID;
      return;
    }
    if (currentExperimentIdRef.current === routeExperimentId) return;
    currentExperimentIdRef.current = routeExperimentId;
  }, [currentModule, selectedExperimentID, routeExperimentId]);
  var currentExperimentId = currentExperimentIdRef.current;
  var experiment = useSelector(function (state) {
    return state === null || state === void 0 ? void 0 : state.experiments[currentExperimentId];
  });
  var experimentName = (experimentData === null || experimentData === void 0 ? void 0 : experimentData.experimentName) || (experiment === null || experiment === void 0 ? void 0 : experiment.name);
  var _useSelector = useSelector(getBackendStatus(currentExperimentId)),
    backendLoading = _useSelector.loading,
    backendError = _useSelector.error,
    backendStatus = _useSelector.status;
  var qcStatusKey = backendStatus === null || backendStatus === void 0 || (_backendStatus$pipeli = backendStatus.pipeline) === null || _backendStatus$pipeli === void 0 ? void 0 : _backendStatus$pipeli.status;
  var qcRunning = qcStatusKey === 'RUNNING';
  var qcRunningError = backendErrors.includes(qcStatusKey);
  var gem2sStatusKey = backendStatus === null || backendStatus === void 0 || (_backendStatus$gem2s = backendStatus.gem2s) === null || _backendStatus$gem2s === void 0 ? void 0 : _backendStatus$gem2s.status;
  var gem2sRunning = gem2sStatusKey === 'RUNNING';
  var gem2sRunningError = backendErrors.includes(gem2sStatusKey);
  var completedGem2sSteps = backendStatus === null || backendStatus === void 0 || (_backendStatus$gem2s2 = backendStatus.gem2s) === null || _backendStatus$gem2s2 === void 0 ? void 0 : _backendStatus$gem2s2.completedSteps;
  var obj2sStatusKey = backendStatus === null || backendStatus === void 0 || (_backendStatus$obj2s = backendStatus.obj2s) === null || _backendStatus$obj2s === void 0 ? void 0 : _backendStatus$obj2s.status;
  var isObj2s = obj2sStatusKey && obj2sTechs.includes(selectedTechnology);
  var _useState2 = useState(null),
    pipelinesRerunStatus = _useState2[0],
    setPipelinesRerunStatus = _useState2[1];
  var obj2sRunning = obj2sStatusKey === 'RUNNING' && isObj2s;
  var obj2sRunningError = backendErrors.includes(obj2sStatusKey) && isObj2s;
  var completedObj2sSteps = backendStatus === null || backendStatus === void 0 || (_backendStatus$obj2s2 = backendStatus.obj2s) === null || _backendStatus$obj2s2 === void 0 ? void 0 : _backendStatus$obj2s2.completedSteps;
  var obj2sComplete = obj2sStatusKey === pipelineStatusValues.SUCCEEDED && isObj2s;
  var waitingForQcToLaunch = gem2sStatusKey === pipelineStatusValues.SUCCEEDED && qcStatusKey === pipelineStatusValues.NOT_CREATED;
  // This is used to prevent a race condition where the page would start loading immediately
  // when the backend status was previously loaded. In that case, `backendLoading` is `false`
  // and would be set to true only in the `loadBackendStatus` action, the time between the
  // two events would allow pages to load.
  var _useState3 = useState(false),
    backendStatusRequested = _useState3[0],
    setBackendStatusRequested = _useState3[1];
  useEffect(function () {
    var _experimentData$sampl2;
    if (!currentExperimentId) return;
    if (!backendLoading) dispatch(loadBackendStatus(currentExperimentId));
    // need to load the samples to get the selected technology of the experiment
    // in the future, selected technology can be moved to under .experiments
    if (!samples[experimentData === null || experimentData === void 0 || (_experimentData$sampl2 = experimentData.sampleIds) === null || _experimentData$sampl2 === void 0 ? void 0 : _experimentData$sampl2[0]]) dispatch(loadSamples(routeExperimentId));
    if (isBrowser) {
      import('utils/socketConnection').then(function (_ref) {
        var connectionPromise = _ref.default;
        return connectionPromise;
      }).then(function (io) {
        var cb = experimentUpdatesHandler(dispatch);

        // Unload all previous socket.io hooks that may have been created for a different
        // experiment.
        io.off();
        io.on("ExperimentUpdates-".concat(currentExperimentId), function (update) {
          return cb(currentExperimentId, update);
        });
      });
    }
  }, [routeExperimentId]);
  useEffect(function () {
    if (backendStatusRequested) {
      return;
    }
    setBackendStatusRequested(true);
  }, [backendLoading]);
  useEffect(function () {
    var _experiment$sampleIds;
    if (!experiment || !backendStatus) return;

    // The value of backend status is null for new experiments that have never run
    var setupPipeline = isObj2s ? 'obj2s' : 'gem2s';
    var _ref2 = backendStatus !== null && backendStatus !== void 0 ? backendStatus : {},
      qcBackendStatus = _ref2.pipeline,
      setupBackendStatus = _ref2[setupPipeline];
    if (!setupBackendStatus || !(experiment !== null && experiment !== void 0 && (_experiment$sampleIds = experiment.sampleIds) !== null && _experiment$sampleIds !== void 0 && _experiment$sampleIds.length) > 0) return;
    setPipelinesRerunStatus(calculatePipelinesRerunStatus(setupBackendStatus, qcBackendStatus, experiment, isObj2s));
  }, [backendStatus, experiment, samples]);
  useEffect(function () {
    dispatch(loadUser());
  }, []);
  if (!user) return __jsx(React.Fragment, null);
  var getStatusObject = function getStatusObject(type, status) {
    var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var completedSteps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    return _objectSpread(_objectSpread({
      type: type,
      status: status
    }, message && {
      message: message
    }), completedSteps && {
      completedSteps: completedSteps
    });
  };
  var gem2sNotCreated = checkEveryIsValue([gem2sStatusKey, obj2sStatusKey], pipelineStatusValues.NOT_CREATED);
  var getObj2sStatus = function getObj2sStatus() {
    if (obj2sRunningError) {
      var _backendStatus$obj2s3;
      var _errorMessage = pipelineErrorUserMessages[backendStatus === null || backendStatus === void 0 || (_backendStatus$obj2s3 = backendStatus.obj2s) === null || _backendStatus$obj2s3 === void 0 || (_backendStatus$obj2s3 = _backendStatus$obj2s3.error) === null || _backendStatus$obj2s3 === void 0 ? void 0 : _backendStatus$obj2s3.error];
      return getStatusObject('obj2s', 'error', _errorMessage);
    }
    if (obj2sRunning) {
      return getStatusObject('obj2s', 'running', null, completedObj2sSteps);
    }
    return null;
  };
  var getGem2sStatus = function getGem2sStatus() {
    if (gem2sRunningError) return getStatusObject('gem2s', 'error');
    if (gem2sRunning && experiment !== null && experiment !== void 0 && experiment.isSubsetted) {
      return getStatusObject('gem2s', 'subsetting', null, completedGem2sSteps);
    }
    if (gem2sRunning || waitingForQcToLaunch) {
      return getStatusObject('gem2s', 'running', null, completedGem2sSteps);
    }
    if (gem2sNotCreated) return getStatusObject('gem2s', 'toBeRun');
    return null;
  };
  var getQcStatus = function getQcStatus() {
    if (currentModule !== modules.DATA_PROCESSING) {
      if (qcRunningError) return getStatusObject('qc', 'error');
      if (qcRunning) return getStatusObject('qc', 'running');
      if (qcStatusKey === pipelineStatusValues.NOT_CREATED) {
        return getStatusObject('qc', 'toBeRun');
      }
    }
    return null;
  };
  var getCurrentStatusScreen = function getCurrentStatusScreen() {
    if (isObj2s) {
      return getObj2sStatus();
    }
    return getGem2sStatus() || getQcStatus();
  };
  var currentStatusScreen = getCurrentStatusScreen();
  var BigLogo = function BigLogo() {
    return __jsx("div", {
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(315deg, #5B070A 0%, #8f0b10 30%, #A80D12 100%)',
        paddingTop: '10px',
        paddingBottom: '10px',
        pointerEvents: 'none',
        userSelect: 'none'
      }
    }, __jsx("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: 200,
      height: 50
    }, __jsx("defs", {
      id: "svg_document_defs"
    }, __jsx("style", {
      id: "IBM Plex Sans_Google_Webfont_import"
    }, "@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans);")), __jsx("g", {
      transform: "translate(20, 25)"
    }, __jsx("text", {
      style: {
        outlineStyle: 'none'
      },
      fontWeight: "500",
      textRendering: "geometricPrecision",
      fontFamily: "IBM Plex Sans",
      fill: "#F0F2F5",
      fontSize: "25.00px",
      textAnchor: "start",
      dominantBaseline: "middle"
    }, "Cellenics\xAE"), getDomainSpecificContent('ExtraLogoText'))));
  };
  var SmallLogo = function SmallLogo() {
    return __jsx("div", {
      style: {
        background: 'linear-gradient(315deg, #5B070A 0%, #8f0b10 30%, #A80D12 100%)',
        paddingTop: '8px',
        paddingBottom: '8px',
        pointerEvents: 'none',
        userSelect: 'none'
      }
    }, __jsx("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: 100,
      height: 30
    }, __jsx("defs", {
      id: "svg_document_defs"
    }, __jsx("style", {
      id: "IBM Plex Sans_Google_Webfont_import"
    }, "@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans);")), __jsx("g", null, __jsx("text", {
      style: {
        outlineStyle: 'none'
      },
      x: "40px",
      fontWeight: "500",
      textRendering: "geometricPrecision",
      fontFamily: "IBM Plex Sans",
      y: "24px",
      fill: "#F0F2F5",
      fontSize: "25.00px",
      textAnchor: "middle"
    }, "Cs"))));
  };
  var menuLinks = [{
    module: modules.DATA_MANAGEMENT,
    icon: __jsx(FolderOpenOutlined, null),
    name: 'Data Management',
    disableIfNoExperiment: false,
    disabledByPipelineStatus: true,
    disabledIfObj2sComplete: false
  }, {
    module: modules.DATA_PROCESSING,
    icon: __jsx(BuildOutlined, null),
    name: 'Data Processing',
    disableIfNoExperiment: true,
    disabledByPipelineStatus: false,
    disabledIfObj2sComplete: true
  }, {
    module: modules.DATA_EXPLORATION,
    icon: __jsx(FundViewOutlined, null),
    name: 'Data Exploration',
    disableIfNoExperiment: true,
    disabledByPipelineStatus: true,
    disabledIfObj2sComplete: false
  }, {
    module: modules.PLOTS_AND_TABLES,
    icon: __jsx(DatabaseOutlined, null),
    name: 'Plots and Tables',
    disableIfNoExperiment: true,
    disabledByPipelineStatus: true,
    disabledIfObj2sComplete: false
  }];
  var renderContent = function renderContent() {
    if (routeExperimentId) {
      if (backendLoading || !backendStatusRequested) {
        return __jsx(PreloadContent, null);
      }
      if (currentStatusScreen && currentStatusScreen.type !== 'qc') {
        return __jsx(GEM2SLoadingScreen, {
          experimentId: routeExperimentId,
          pipelineStatus: currentStatusScreen.status,
          pipelineType: currentStatusScreen.type,
          pipelineErrorMessage: currentStatusScreen === null || currentStatusScreen === void 0 ? void 0 : currentStatusScreen.message,
          completedSteps: currentStatusScreen === null || currentStatusScreen === void 0 ? void 0 : currentStatusScreen.completedSteps
        });
      }
      if ((currentStatusScreen === null || currentStatusScreen === void 0 ? void 0 : currentStatusScreen.type) === 'qc') {
        return __jsx(PipelineRedirectToDataProcessing, {
          experimentId: routeExperimentId,
          pipelineStatus: currentStatusScreen.status
        });
      }
      if (obj2sComplete && currentModule === modules.DATA_PROCESSING) {
        navigateTo(modules.DATA_EXPLORATION, {
          experimentId: routeExperimentId
        });
        return __jsx(React.Fragment, null);
      }
      if (process.env.NODE_ENV === 'development') {
        return children;
      }
    }
    return children;
  };
  var menuItemRender = function menuItemRender(_ref3) {
    var module = _ref3.module,
      icon = _ref3.icon,
      name = _ref3.name,
      disableIfNoExperiment = _ref3.disableIfNoExperiment,
      disabledByPipelineStatus = _ref3.disabledByPipelineStatus,
      disabledIfObj2sComplete = _ref3.disabledIfObj2sComplete;
    var needRerunPipeline = pipelinesRerunStatus === null || pipelinesRerunStatus.rerun;
    var notProcessedExperimentDisable = !routeExperimentId && disableIfNoExperiment && needRerunPipeline;
    var pipelineStatusDisable = disabledByPipelineStatus && (backendError || gem2sRunning || gem2sRunningError || waitingForQcToLaunch || qcRunning || qcRunningError || obj2sRunning || obj2sRunningError);
    var DATA_EXPLORATION = modules.DATA_EXPLORATION,
      DATA_MANAGEMENT = modules.DATA_MANAGEMENT,
      DATA_PROCESSING = modules.DATA_PROCESSING,
      PLOTS_AND_TABLES = modules.PLOTS_AND_TABLES;

    // disable links if user is not in one of the experiment analysis modules
    var nonExperimentModule = ![DATA_EXPLORATION, DATA_MANAGEMENT, DATA_PROCESSING, PLOTS_AND_TABLES].includes(currentModule) && disableIfNoExperiment;
    var obj2sCompleteDisable = disabledIfObj2sComplete && obj2sComplete;
    return {
      key: module,
      icon: icon,
      label: name,
      disabled: notProcessedExperimentDisable || pipelineStatusDisable || obj2sCompleteDisable || nonExperimentModule,
      onClick: function onClick() {
        return navigateTo(module, {
          experimentId: currentExperimentId
        });
      }
    };
  };
  if (!user) return __jsx(React.Fragment, null);
  var mainMenuItems = menuLinks.filter(function (item) {
    return !item.disableIfNoExperiment;
  }).map(menuItemRender);
  var groupMenuItems = menuLinks.filter(function (item) {
    return item.disableIfNoExperiment;
  }).map(menuItemRender);
  var groupItem = {
    type: 'group',
    label: !collapsed && __jsx(_Tooltip, {
      title: experimentName,
      placement: "right"
    }, __jsx(_Space, {
      direction: "vertical",
      style: {
        width: '100%',
        cursor: 'default'
      }
    }, __jsx(Text, {
      style: {
        width: '100%',
        color: '#999999'
      },
      strong: true,
      ellipsis: true
    }, experimentName || 'No analysis'), experimentName && __jsx(Text, {
      style: {
        color: '#999999'
      }
    }, "Current analysis"))),
    children: groupMenuItems
  };
  var menuItems = [].concat(_toConsumableArray(mainMenuItems), [groupItem]);
  return __jsx(React.Fragment, null, __jsx(DndProvider, {
    backend: MultiBackend,
    options: HTML5ToTouch
  }, privacyPolicyIsNotAccepted(user, domainName) && __jsx(PrivacyPolicyIntercept, {
    user: user,
    onOk: function onOk() {
      return dispatch(loadUser());
    }
  }), __jsx(BrowserAlert, null), __jsx(_Layout, {
    style: {
      minHeight: '100vh'
    }
  }, __jsx(Sider, {
    style: {
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0
    },
    width: 210,
    theme: "dark",
    mode: "inline",
    collapsible: true,
    collapsed: collapsed,
    onCollapse: function onCollapse(collapse) {
      return setCollapsed(collapse);
    }
  }, __jsx("div", {
    style: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  }, collapsed ? __jsx(SmallLogo, null) : __jsx(BigLogo, null), __jsx(_Menu, {
    "data-test-id": integrationTestConstants.ids.NAVIGATION_MENU,
    theme: "dark",
    selectedKeys: menuLinks.filter(function (_ref4) {
      var module = _ref4.module;
      return module === currentModule;
    }).map(function (_ref5) {
      var module = _ref5.module;
      return module;
    }),
    mode: "inline",
    items: menuItems
  }))), __jsx(_Layout, {
    style: !collapsed ? {
      marginLeft: '210px'
    } : {
      marginLeft: '80px'
    } // this is the collapsed width for our sider
  }, renderContent()))));
};
ContentWrapper.defaultProps = {
  routeExperimentId: null,
  experimentData: null,
  children: null
};
export default ContentWrapper;