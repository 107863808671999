import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
/* eslint-disable no-param-reassign */
import produce from 'immer';

// Only works with scratchpad
var cellSetsCreate = produce(function (draft, action) {
  var _action$payload = action.payload,
    key = _action$payload.key,
    name = _action$payload.name,
    color = _action$payload.color,
    cellIds = _action$payload.cellIds,
    type = _action$payload.type;
  var scratchpadIndex = draft.hierarchy.findIndex(function (rootNode) {
    return rootNode.key === 'scratchpad';
  });
  draft.hierarchy[scratchpadIndex].children.push({
    key: key
  });
  draft.properties[key] = {
    key: key,
    name: name,
    color: color,
    cellIds: new Set(cellIds),
    type: type,
    parentNodeKey: 'scratchpad'
  };
});
export default cellSetsCreate;