/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
import getInitialState from 'redux/reducers/genes/getInitialState';
var markerGenesLoaded = produce(function (draft, action) {
  var _action$payload = action.payload,
    plotUuid = _action$payload.plotUuid,
    _action$payload$data = _action$payload.data,
    orderedGeneNames = _action$payload$data.orderedGeneNames,
    rawExpression = _action$payload$data.rawExpression,
    truncatedExpression = _action$payload$data.truncatedExpression,
    zScore = _action$payload$data.zScore,
    stats = _action$payload$data.stats,
    cellOrder = _action$payload$data.cellOrder;
  var downsampledExpressionMatrix = original(draft).expression.downsampled.matrix;
  downsampledExpressionMatrix.setGeneExpression(orderedGeneNames, rawExpression, truncatedExpression, zScore, stats);
  draft.expression.downsampled.cellOrder = cellOrder;
  draft.expression.views[plotUuid] = {
    fetching: false,
    error: false,
    data: orderedGeneNames,
    markers: true
  };
  draft.markers.loading = false;
  draft.markers.error = false;
}, getInitialState());
export default markerGenesLoaded;