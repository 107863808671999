import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var pipelineStart = produce(function (draft) {
  draft.processing.meta.changedQCFilters = new Set();
}, initialState);
export default pipelineStart;