/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'redux/reducers/differentialExpression/initialState';
var differentialExpressionSetGeneOrdering = produce(function (draft, action) {
  var _action$payload = action.payload,
    orderBy = _action$payload.orderBy,
    orderDirection = _action$payload.orderDirection;
  draft.comparison.ordering = {
    orderBy: orderBy,
    orderDirection: orderDirection
  };
}, initialState);
export default differentialExpressionSetGeneOrdering;