import { USER_LOADED } from 'redux/actionTypes/user';
import initialState from 'redux/reducers/user/initialState';
import userLoaded from 'redux/reducers/user/userLoaded';
var userReducer = function userReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case USER_LOADED:
      {
        return userLoaded(state, action);
      }
    default:
      {
        return state;
      }
  }
};
export default userReducer;