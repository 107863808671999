import "antd/lib/result/style";
import _Result from "antd/lib/result";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
var __jsx = React.createElement;
import React from 'react';
import Link from 'next/link';
var PipelineRedirectToDataProcessing = function PipelineRedirectToDataProcessing(_ref) {
  var experimentId = _ref.experimentId,
    pipelineStatus = _ref.pipelineStatus;
  var path = '/experiments/[experimentId]/data-processing';
  var texts = {
    error: {
      status: 'error',
      title: 'We\'ve had an issue while working on your project.',
      subTitle: 'Please go to Data Processing and try again.',
      image: '/undraw_Abstract_re_l9xy.svg',
      alt: 'A woman confusedly staring at an abstract drawing.'
    },
    running: {
      status: 'info',
      title: 'We\'re working on your project...',
      subTitle: 'You can check the progress we\'ve made in Data Processing.',
      image: '/undraw_Dev_focus_re_6iwt.svg',
      alt: 'A woman working in front of a computer.'
    },
    toBeRun: {
      status: 'info',
      title: 'Let\'s process your data first.',
      subTitle: 'You need to process your data before it can be explored. To begin, go to Data Processing.',
      image: '/undraw_To_the_stars_qhyy.svg',
      alt: 'A rocket ship ready for take-off.'
    },
    runningStep: {
      status: 'info',
      title: 'Your data is getting ready.',
      subTitle: 'We\'re preparing the data for this step, please wait. This will take a few minutes.',
      image: '/undraw_tasks_re_v2v4.svg',
      alt: 'A woman in a wheelchair looking at a list of partially completed items.'
    }
  };
  var _texts$pipelineStatus = texts[pipelineStatus],
    status = _texts$pipelineStatus.status,
    title = _texts$pipelineStatus.title,
    subTitle = _texts$pipelineStatus.subTitle,
    image = _texts$pipelineStatus.image,
    alt = _texts$pipelineStatus.alt;
  return __jsx(_Result, {
    status: status,
    title: title,
    subTitle: subTitle,
    icon: __jsx("img", {
      width: 250,
      height: 250,
      alt: alt,
      src: image,
      style: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%'
      }
    }),
    extra: pipelineStatus !== 'runningStep' && __jsx(Link, {
      as: path.replace('[experimentId]', experimentId),
      href: path,
      passHref: true
    }, __jsx(_Button, {
      type: "primary",
      key: "console"
    }, "Go to Data Processing"))
  });
};
export default PipelineRedirectToDataProcessing;