var EXPERIMENTS = 'experiments';

/**
 * Create experiments
 */
var EXPERIMENTS_CREATED = "".concat(EXPERIMENTS, "/created");

/**
 * Create experiments
 */
var EXPERIMENTS_LOADING = "".concat(EXPERIMENTS, "/loading");

/**
 * Experiments loaded
 */
var EXPERIMENTS_LOADED = "".concat(EXPERIMENTS, "/loaded");

/**
 * Example experiments loaded
 */
var EXPERIMENTS_EXAMPLES_LOADED = "".concat(EXPERIMENTS, "/examplesLoaded");

/**
 * Experiments updated
 */
var EXPERIMENTS_UPDATED = "".concat(EXPERIMENTS, "/updated");

/**
 * Delete experiments
 */
var EXPERIMENTS_DELETED = "".concat(EXPERIMENTS, "/deleted");

/**
 * Saving experiment
 */
var EXPERIMENTS_SAVING = "".concat(EXPERIMENTS, "/saving");
var EXPERIMENTS_SAVED = "".concat(EXPERIMENTS, "/saved");

/**
 * Update experiments
 */
var EXPERIMENTS_ERROR = "".concat(EXPERIMENTS, "/error");
var EXPERIMENTS_SWITCH = "".concat(EXPERIMENTS, "/switch");

/**
 * Set active project.
 */
var EXPERIMENTS_SET_ACTIVE = "".concat(EXPERIMENTS, "/setActive");

/**
 * Add project metadata.
 */
var EXPERIMENTS_METADATA_CREATE = "".concat(EXPERIMENTS, "/metadataCreate");

/**
 * Update project metadata.
 */
var EXPERIMENTS_METADATA_RENAME = "".concat(EXPERIMENTS, "/metadataRename");

/**
 * Delete project metadta.
 */
var EXPERIMENTS_METADATA_DELETE = "".concat(EXPERIMENTS, "/metadataDelete");

// cell level metadata of an experiment updated
var EXPERIMENT_CELL_METADATA_UPDATED = "".concat(EXPERIMENTS, "/cellLevelMetadataUpdated");
export { EXPERIMENTS_CREATED, EXPERIMENTS_UPDATED, EXPERIMENTS_ERROR, EXPERIMENTS_LOADING, EXPERIMENTS_LOADED, EXPERIMENTS_EXAMPLES_LOADED, EXPERIMENTS_DELETED, EXPERIMENTS_SAVING, EXPERIMENTS_SAVED, EXPERIMENTS_SWITCH, EXPERIMENTS_SET_ACTIVE, EXPERIMENTS_METADATA_CREATE, EXPERIMENTS_METADATA_RENAME, EXPERIMENTS_METADATA_DELETE, EXPERIMENT_CELL_METADATA_UPDATED };