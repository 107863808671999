import _merge from "lodash/merge";
import _isNil from "lodash/isNil";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
import { initialPlotConfigStates } from 'redux/reducers/componentConfig/initialState';
var configsReplaced = produce(function (draft, action) {
  var updatedConfigs = action.payload.updatedConfigs;
  var originalState = original(draft);
  updatedConfigs.forEach(function (_ref) {
    var id = _ref.id,
      updatedConfig = _ref.updatedConfig;
    // If config is not loaded, no need to update it
    if (_isNil(originalState[id])) return;
    var plotType = originalState[id].plotType;
    var newConfig = _merge({}, initialPlotConfigStates[plotType], updatedConfig);
    draft[id].config = newConfig;
  });
});
export default configsReplaced;