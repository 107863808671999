var writeToFileURL = function writeToFileURL(data) {
  var blob;
  if (data instanceof Blob) {
    blob = data;
  } else {
    blob = new Blob([data], {
      type: 'text/plain'
    });
  }
  var textFileURL = window.URL.createObjectURL(blob);

  // returns a URL that can be used as a href
  return textFileURL;
};
export default writeToFileURL;