import _slicedToArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["meta"];
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
var experimentsMetadataRename = produce(function (draft, action) {
  var _action$payload = action.payload,
    oldKey = _action$payload.oldKey,
    newKey = _action$payload.newKey,
    experimentId = _action$payload.experimentId;
  var _original = original(draft),
    meta = _original.meta,
    samples = _objectWithoutProperties(_original, _excluded);
  var sampleEntries = Object.entries(samples);
  var sampleIdsFromExperiment = sampleEntries.filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      currentExpId = _ref2[1].experimentId;
    return currentExpId === experimentId;
  }).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 1),
      sampleId = _ref4[0];
    return sampleId;
  });

  // Move the metadata in oldKey to newKey and then remove the old one
  sampleIdsFromExperiment.forEach(function (sampleId) {
    var value = draft[sampleId].metadata[oldKey];
    delete draft[sampleId].metadata[oldKey];
    draft[sampleId].metadata[newKey] = value;
  });
});
export default experimentsMetadataRename;