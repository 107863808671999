import _map from "lodash/map";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
var experimentsLoaded = produce(function (draft, action) {
  var experiments = action.payload.experiments;
  var originalState = original(draft);
  var newActiveExperimentId = originalState.meta.activeExperimentId;

  // If the current active experiment no longer exists, change it
  if (!Object.keys(originalState).includes(newActiveExperimentId)) {
    var _experiments$;
    newActiveExperimentId = (_experiments$ = experiments[0]) === null || _experiments$ === void 0 ? void 0 : _experiments$.id;
  }
  var ids = _map(experiments, 'id');
  draft.meta.activeExperimentId = newActiveExperimentId;
  draft.meta.loading = false;
  draft.ids = ids;
  experiments.forEach(function (experiment) {
    draft[experiment.id] = experiment;
  });
});
export default experimentsLoaded;