import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
/* eslint-disable global-require */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createWrapper } from 'next-redux-wrapper';
import { enableMapSet } from 'immer';
import rootReducer from 'redux/reducers/index';
enableMapSet();
var bindMiddleware = function bindMiddleware(middleware) {
  var _require = require('redux-devtools-extension'),
    composeWithDevTools = _require.composeWithDevTools;

  // eslint-disable-next-line import/no-extraneous-dependencies
  var _require2 = require('redux-logger'),
    createLogger = _require2.createLogger;

  // do not log server-side redux actions
  middleware.push(createLogger({
    predicate: function predicate() {
      return typeof window !== 'undefined';
    }
  }));
  return composeWithDevTools(applyMiddleware.apply(void 0, _toConsumableArray(middleware)));
};
var makeStore = function makeStore() {
  var store = createStore(rootReducer, bindMiddleware([thunk]));

  // IF REDUCERS WERE CHANGED, RELOAD WITH INITIAL STATE
  if (module.hot) {
    module.hot.accept('./reducers', function () {
      var createNextReducer = require('./reducers').default;
      store.replaceReducer(createNextReducer(rootReducer));
    });
  }
  return store;
};
var wrapper = createWrapper(makeStore, {
  debug: false
});
export { wrapper, makeStore };