import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import getCellSets from 'redux/selectors/cellSets/getCellSets';
var getCellSetsData = function getCellSetsData(children, properties) {
  return children.map(function (_ref) {
    var key = _ref.key;
    var name = properties[key].name;
    return {
      key: key,
      name: name
    };
  });
};
var getCellSetsHierarchy = function getCellSetsHierarchy() {
  return function (state) {
    if (!state || !state.accessible) {
      return [];
    }
    var hierarchy = state.hierarchy.map(function (cellClass) {
      var _state$properties$cel, _state$properties$cel2, _cellClass$children;
      return {
        key: cellClass.key,
        name: (_state$properties$cel = state.properties[cellClass.key]) === null || _state$properties$cel === void 0 ? void 0 : _state$properties$cel.name,
        type: (_state$properties$cel2 = state.properties[cellClass.key]) === null || _state$properties$cel2 === void 0 ? void 0 : _state$properties$cel2.type,
        children: getCellSetsData((_cellClass$children = cellClass === null || cellClass === void 0 ? void 0 : cellClass.children) !== null && _cellClass$children !== void 0 ? _cellClass$children : [], state.properties)
      };
    });
    return hierarchy;
  };
};
export default createMemoizedSelector(getCellSetsHierarchy, {
  inputSelectors: getCellSets()
});