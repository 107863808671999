import { EXPERIMENT_SETTINGS_PROCESSING_CONFIG_LOADED } from '../../../actionTypes/experimentSettings';
var loadedProcessingConfig = function loadedProcessingConfig(experimentId, processingConfig) {
  var fromGem2s = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return function (dispatch) {
    dispatch({
      type: EXPERIMENT_SETTINGS_PROCESSING_CONFIG_LOADED,
      payload: {
        data: processingConfig,
        fromGem2s: fromGem2s
      }
    });
  };
};
export default loadedProcessingConfig;