/* eslint-disable no-param-reassign */
import produce, { current } from 'immer';
import initialState from '../initialState';
var setSampleFilterSettingsAuto = produce(function (draft, action) {
  var _action$payload = action.payload,
    step = _action$payload.step,
    sampleId = _action$payload.sampleId,
    isAuto = _action$payload.isAuto;
  draft.processing[step][sampleId].auto = isAuto;
  if (isAuto) {
    draft.processing[step][sampleId].filterSettings = current(draft.processing[step][sampleId]).defaultFilterSettings;
  }
}, initialState);
export default setSampleFilterSettingsAuto;