import _slicedToArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.values.js";
import "core-js/modules/es7.object.entries.js";
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { getCellSetKey } from 'utils/cellSets';
var cellSetsDelete = produce(function (draft, action) {
  var deletedKey = action.payload.key;
  Object.values(draft.comparison.group).forEach(function (comparisonGroup) {
    Object.entries(comparisonGroup).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        comparisonKey = _ref2[0],
        comparisonValue = _ref2[1];
      if (getCellSetKey(comparisonValue) !== deletedKey) return;
      comparisonGroup[comparisonKey] = null;
    });
  });
});
export default cellSetsDelete;