import produce from 'immer';
// called when an experiment is deleted - deletes the backend status

var experimentsDeleted = produce(function (draft, action) {
  var experimentIds = action.payload.experimentIds;
  experimentIds.forEach(function (id) {
    // eslint-disable-next-line no-param-reassign
    delete draft[id];
  });
});
export default experimentsDeleted;