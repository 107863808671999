/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var pipelineVersionUpdated = produce(function (draft, action) {
  var _action$payload = action.payload,
    experimentId = _action$payload.experimentId,
    pipelineVersion = _action$payload.pipelineVersion;
  if (draft[experimentId]) {
    draft[experimentId].pipelineVersion = pipelineVersion;
  }
}, initialState);
export default pipelineVersionUpdated;