import _objectWithoutProperties from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["enabled", "auto", "filterSettings", "prefiltered"];
import "core-js/modules/es6.object.keys.js";
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from '../initialState';
var setQCStepEnabled = produce(function (draft, action) {
  var _action$payload = action.payload,
    step = _action$payload.step,
    enabled = _action$payload.enabled;
  var _draft$processing$ste = draft.processing[step],
    oldEnable = _draft$processing$ste.enabled,
    auto = _draft$processing$ste.auto,
    filterSettings = _draft$processing$ste.filterSettings,
    prefiltered = _draft$processing$ste.prefiltered,
    sampleIds = _objectWithoutProperties(_draft$processing$ste, _excluded);

  // Filter is applied per samples in the pipeline
  Object.keys(sampleIds).forEach(function (sampleId) {
    draft.processing[step][sampleId].enabled = enabled;
  });
  draft.processing.meta.changedQCFilters.add(step);
}, initialState);
export default setQCStepEnabled;