import { UPDATE_CONFIG } from '../../actionTypes/componentConfig';
var updatePlotConfig = function updatePlotConfig(plotUuid, configChanges) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_CONFIG,
      payload: {
        plotUuid: plotUuid,
        configChanges: configChanges
      }
    });
  };
};
export default updatePlotConfig;