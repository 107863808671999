var EMBEDDINGS = 'embeddings';

/**
 * Turns on the loading condition for the embedding.
 */
var EMBEDDINGS_LOADING = "".concat(EMBEDDINGS, "/loading");

/**
 * Sets the state of the embedding to be successfully loaded, with the appropriate embedding data.
 */
var EMBEDDINGS_LOADED = "".concat(EMBEDDINGS, "/loaded");

/**
 * Sets an error condition for a specific embedding.
 */
var EMBEDDINGS_ERROR = "".concat(EMBEDDINGS, "/error");
export { EMBEDDINGS_LOADING, EMBEDDINGS_LOADED, EMBEDDINGS_ERROR };