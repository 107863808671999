/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState, { metaInitialState } from './initialState';
var updateExperimentInfo = produce(function (draft, action) {
  var _action$payload = action.payload,
    experimentId = _action$payload.experimentId,
    experimentName = _action$payload.experimentName,
    sampleIds = _action$payload.sampleIds,
    pipelineVersion = _action$payload.pipelineVersion;
  draft.info.experimentId = experimentId;
  draft.info.experimentName = experimentName;
  draft.info.sampleIds = sampleIds;
  draft.info.pipelineVersion = pipelineVersion;

  // Experiment id was updated so processing config requires reloading
  draft.processing = {
    meta: metaInitialState
  };
}, initialState);
export default updateExperimentInfo;