/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'redux/reducers/componentConfig/initialState';
var resetConfig = produce(function (draft, action) {
  var _action$payload = action.payload,
    plotUuid = _action$payload.plotUuid,
    config = _action$payload.config;
  draft[plotUuid].config = config;
  draft[plotUuid].outstandingChanges = false;
}, initialState);
export default resetConfig;