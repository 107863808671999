var BACKEND_STATUS = 'backendStatus';

/**
 * Starts loading pipeline status from the API.
 */
var BACKEND_STATUS_LOADING = "".concat(BACKEND_STATUS, "/backendStatusLoading");

/**
 * Loaded pipeline status from the API.
 */
var BACKEND_STATUS_LOADED = "".concat(BACKEND_STATUS, "/backendStatusLoaded");

/**
 * Backend status update was received.
 */
var BACKEND_STATUS_UPDATED = "".concat(BACKEND_STATUS, "/backendStatusUpdated");

/**
 * Error state when backend status could not be loaded from the API.
 */
var BACKEND_STATUS_ERROR = "".concat(BACKEND_STATUS, "/backendStatusError");
export { BACKEND_STATUS_LOADING, BACKEND_STATUS_LOADED, BACKEND_STATUS_UPDATED, BACKEND_STATUS_ERROR };