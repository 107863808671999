import _pull from "lodash/pull";
import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
import initialState from 'redux/reducers/componentConfig/initialState';

// Necessary for the interactiveHeatmap, it can't get invalidated
// as easily as other plots (from the api) because it is not persisted
var cellClassDelete = produce(function (draft, action) {
  var cellClassKey = action.payload.key;
  Object.values(draft).forEach(function (value) {
    var _original;
    if ((_original = original(value)) !== null && _original !== void 0 && (_original = _original.config) !== null && _original !== void 0 && _original.groupedTracks.includes(cellClassKey)) {
      _pull(value.config.groupedTracks, cellClassKey);
    }
  });
}, initialState);
export default cellClassDelete;