/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from '../initialState';
var updateSampleProcessingSettingsFromQC = produce(function (draft, action) {
  var _action$payload = action.payload,
    step = _action$payload.step,
    sampleId = _action$payload.sampleId,
    newSettings = _action$payload.newSettings;
  draft.processing[step][sampleId] = newSettings;
  draft.originalProcessing[step][sampleId] = newSettings;
}, initialState);
export default updateSampleProcessingSettingsFromQC;