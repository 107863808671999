import produce from 'immer';
import initialState from './initialState';
/* eslint-disable no-param-reassign */

var cellInfoFocus = produce(function (draft, action) {
  var _action$payload = action.payload,
    store = _action$payload.store,
    key = _action$payload.key;
  draft.focus.store = store;
  draft.focus.key = key;
}, initialState);
export default cellInfoFocus;