/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var backendStatusLoading = produce(function (draft, action) {
  var experimentId = action.payload.experimentId;
  draft[experimentId] = {
    loading: true,
    error: false,
    status: null
  };
}, initialState);
export default backendStatusLoading;