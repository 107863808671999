import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["parentNodeKey"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
/**
 * Composes the tree from the Redux store. Combines `hierarchy` with
 * `properties` to create a tree we can render.
 *
 * `type` is the type of cell set we want to get. the default is `null`, which retrieves
 * all cell sets, including metadata.
 *
 */
var composeTree = function composeTree(hierarchy, properties) {
  var filterTypes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var composeTreeRecursive = function composeTreeRecursive(data, types) {
    if (!data) {
      return;
    }
    return data.filter(function (root) {
      return !types || types.includes(properties[root.key].type);
    }).map(function (node) {
      var _properties$node$key2;
      // eslint-disable-next-line no-unused-vars
      var _properties$node$key = properties[node.key],
        parentNodeKey = _properties$node$key.parentNodeKey,
        restOfProperties = _objectWithoutProperties(_properties$node$key, _excluded);
      return _objectSpread(_objectSpread(_objectSpread({}, node), restOfProperties), {}, {
        cellIds: _toConsumableArray(((_properties$node$key2 = properties[node.key]) === null || _properties$node$key2 === void 0 ? void 0 : _properties$node$key2.cellIds) || []),
        children: node.children ? composeTreeRecursive(node.children, null) : undefined
      });
    });
  };
  return composeTreeRecursive(hierarchy, filterTypes);
};
export default composeTree;