import _cloneDeep from "lodash/cloneDeep";
import "core-js/modules/es6.array.filter.js";
/* eslint-disable no-param-reassign */
import produce, { current } from 'immer';
import initialState from '../initialState';
var copyFilterSettingsToAllSamples = produce(function (draft, action) {
  var _action$payload = action.payload,
    step = _action$payload.step,
    sourceSampleId = _action$payload.sourceSampleId,
    sampleIds = _action$payload.sampleIds;
  var sourceSettings = current(draft.processing[step][sourceSampleId]);
  var samplesToReplace = sampleIds.filter(function (sampleId) {
    return sampleId !== sourceSampleId;
  });
  samplesToReplace.forEach(function (sampleIdToReplace) {
    draft.processing[step][sampleIdToReplace].auto = sourceSettings.auto;
    if (!sourceSettings.auto) {
      draft.processing[step][sampleIdToReplace].filterSettings = _cloneDeep(sourceSettings.filterSettings);
    }
  });
}, initialState);
export default copyFilterSettingsToAllSamples;