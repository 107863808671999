import _sum from "lodash/sum";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.map.js";
var getNumberOfCellsInGrouping = function getNumberOfCellsInGrouping(rootNodeKey, state) {
  var _state$cellSets = state.cellSets,
    hierarchy = _state$cellSets.hierarchy,
    properties = _state$cellSets.properties;
  var rootNode = hierarchy.find(function (_ref) {
    var key = _ref.key;
    return key === rootNodeKey;
  });
  if (!rootNode) return null;
  var cellSetsLengths = rootNode === null || rootNode === void 0 ? void 0 : rootNode.children.map(function (_ref2) {
    var cellSetKey = _ref2.key;
    return properties[cellSetKey].cellIds.size;
  });
  return _sum(cellSetsLengths);
};
export default getNumberOfCellsInGrouping;