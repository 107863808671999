import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
/* eslint-disable no-param-reassign */
import produce from 'immer';
var cellSetsHide = produce(function (draft, action) {
  draft.hidden.add(action.payload.key);
});
var cellSetsUnhide = produce(function (draft, action) {
  draft.hidden.delete(action.payload.key);
});
var cellSetsUnhideAll = produce(function (draft) {
  draft.hidden = new Set();
});
export { cellSetsHide, cellSetsUnhide, cellSetsUnhideAll };