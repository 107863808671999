/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'redux/reducers/componentConfig/initialState';
var saveConfig = produce(function (draft, action) {
  var _action$payload = action.payload,
    plotUuid = _action$payload.plotUuid,
    _action$payload$succe = _action$payload.success,
    success = _action$payload$succe === void 0 ? true : _action$payload$succe;
  draft[plotUuid].outstandingChanges = !success;
}, initialState);
export default saveConfig;