import { CELL_INFO_UPDATE, CELL_INFO_FOCUS, CELL_INFO_UNFOCUS } from '../../actionTypes/cellInfo';
import { EXPERIMENT_SETTINGS_QC_START } from '../../actionTypes/experimentSettings';
import initialState from './initialState';
import updateCellInfo from './updateCellInfo';
import cellInfoFocus from './cellInfoFocus';
import cellInfoUnfocus from './cellInfoUnfocus';
var cellInfoReducer = function cellInfoReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case CELL_INFO_UPDATE:
      {
        return updateCellInfo(state, action);
      }
    case CELL_INFO_FOCUS:
      {
        return cellInfoFocus(state, action);
      }
    case CELL_INFO_UNFOCUS:
      {
        return cellInfoUnfocus(state, action);
      }
    case EXPERIMENT_SETTINGS_QC_START:
      {
        return initialState;
      }
    default:
      {
        return state;
      }
  }
};
export default cellInfoReducer;