import "antd/lib/button/style";
import _Button from "antd/lib/button";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
import React from 'react';
import { AccountId, Environment } from 'utils/deploymentInfo';
import nextConfig from 'next/config';
import config from 'config';
var reusedContent = {
  HelpButton: {
    BiomageUserGuide: __jsx(React.Fragment, null, "Check out the", ' ', __jsx("a", {
      href: "https://www.biomage.net/user-guide",
      target: "_blank",
      rel: "noreferrer"
    }, "user guide", ' '), "and", ' ', __jsx("a", {
      href: "https://www.youtube.com/@biomageltd4616/featured",
      target: "_blank",
      rel: "noreferrer"
    }, " tutorial videos "), __jsx("br", null)),
    OneToOneSupport: __jsx(React.Fragment, null, "For 1-2-1 support with your analysis, contact", ' ', __jsx("a", {
      href: "mailto: ".concat(config.supportEmail)
    }, config.supportEmail))
  },
  ExtraLogoText: __jsx("text", {
    style: {
      outlineStyle: 'none'
    },
    fontWeight: "400",
    textRendering: "geometricPrecision",
    fontFamily: "IBM Plex Sans",
    fill: "#F0F2F5",
    fontSize: "9.00px",
    textAnchor: "start",
    dominantBaseline: "middle",
    y: "20"
  }, "provided by Biomage")
};
var biomageContainerIds = _defineProperty(_defineProperty(_defineProperty({}, Environment.PRODUCTION, 'lkIodjnO'), Environment.STAGING, 'FX7UBNS6'), Environment.DEVELOPMENT, 'lS8ZRMXJ');
var domainSpecificContent = {
  HMS: {
    containerIds: _defineProperty(_defineProperty(_defineProperty({}, Environment.PRODUCTION, 'zdMhc9ey'), Environment.STAGING, 'lMoIVl5D'), Environment.DEVELOPMENT, 'uMEoPBAl'),
    HelpButton: reusedContent.HelpButton.OneToOneSupport,
    matomoName: 'cellenics',
    guidanceFileLink: 'https://drive.google.com/file/d/1VPaB-yofuExinY2pXyGEEx-w39_OPubO/view',
    helpMessage: __jsx(React.Fragment, null, "Email us to find out if we can support your data:", __jsx("a", {
      href: "mailto:".concat(config.supportEmail)
    }, config.supportEmail))
  },
  BIOMAGE: {
    containerIds: biomageContainerIds,
    HelpButton: __jsx(React.Fragment, null, "Ask questions about how to use Cellenics and make feature requests on the", ' ', __jsx("a", {
      href: "https://community.biomage.net/",
      target: "_blank",
      rel: "noreferrer"
    }, "Cellenics community forum"), "! The Biomage team will reply to your message as soon as possible.", __jsx("br", null), __jsx("br", null), reusedContent.HelpButton.BiomageUserGuide),
    HeaderExtraButton: __jsx(_Button, null, __jsx("a", {
      href: "https://courses.biomage.net",
      target: "_blank",
      rel: "noreferrer"
    }, "Courses")),
    matomoName: 'biomage',
    ExtraLogoText: reusedContent.ExtraLogoText,
    guidanceFileLink: 'https://www.biomage.net/user-guide',
    helpMessage: __jsx(React.Fragment, null, "Reach out to us using the feedback button at the top of the page.")
  },
  BIOMAGE_PRIVATE: {
    containerIds: biomageContainerIds,
    HelpButton: __jsx(React.Fragment, null, reusedContent.HelpButton.BiomageUserGuide),
    ExtraLogoText: reusedContent.ExtraLogoText,
    guidanceFileLink: 'https://www.biomage.net/user-guide',
    helpMessage: __jsx(React.Fragment, null, "Reach out to us using the feedback button at the top of the page.")
  }
};
export default function getDomainSpecificContent(component) {
  var _nextConfig;
  var accountId = (_nextConfig = nextConfig()) === null || _nextConfig === void 0 || (_nextConfig = _nextConfig.publicRuntimeConfig) === null || _nextConfig === void 0 ? void 0 : _nextConfig.accountId;
  switch (accountId) {
    case AccountId.HMS:
      return domainSpecificContent.HMS[component];
    case AccountId.BIOMAGE:
      return domainSpecificContent.BIOMAGE[component];
    default:
      return domainSpecificContent.BIOMAGE_PRIVATE[component];
  }
}