/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'redux/reducers/backendStatus/initialState';
import { mergeObjectReplacingArrays } from 'utils/arrayUtils';
var backendStatusUpdated = produce(function (draft, action) {
  var _draft$experimentId;
  var _action$payload = action.payload,
    experimentId = _action$payload.experimentId,
    status = _action$payload.status;
  mergeObjectReplacingArrays((_draft$experimentId = draft[experimentId]) === null || _draft$experimentId === void 0 ? void 0 : _draft$experimentId.status, status);
}, initialState);
export default backendStatusUpdated;