// eslint-disable-next-line camelcase
import { JSON_parse } from 'uint8array-json-parser';
var resultParsers = {
  GetNormalizedExpression: function GetNormalizedExpression(result) {
    return result;
  },
  DownloadAnnotSeuratObject: function DownloadAnnotSeuratObject(result) {
    return result;
  },
  default: function _default(result) {
    return JSON_parse(result);
  }
};
var parseResult = function parseResult(result, taskName) {
  var _resultParsers$taskNa;
  var parser = (_resultParsers$taskNa = resultParsers[taskName]) !== null && _resultParsers$taskNa !== void 0 ? _resultParsers$taskNa : resultParsers.default;
  return parser(result);
};
export default parseResult;