import "core-js/modules/es6.array.find-index.js";
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { arrayMoveMutable } from 'utils/arrayUtils';
var cellSetsReorder = produce(function (draft, action) {
  var _action$payload = action.payload,
    cellSetKey = _action$payload.cellSetKey,
    newPosition = _action$payload.newPosition,
    cellClassKey = _action$payload.cellClassKey;
  var cellClassIndex = draft.hierarchy.findIndex(function (rootNode) {
    return rootNode.key === cellClassKey;
  });
  var cellClassOrder = draft.hierarchy[cellClassIndex].children;
  var currentPosition = cellClassOrder.findIndex(function (cellSet) {
    return cellSet.key === cellSetKey;
  });
  arrayMoveMutable(cellClassOrder, currentPosition, newPosition);
});
export default cellSetsReorder;