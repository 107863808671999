import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { HYDRATE } from 'next-redux-wrapper';
import initialState from './initialState';
import { EXPERIMENT_SETTINGS_INFO_UPDATE, EXPERIMENT_SETTINGS_PROCESSING_CONFIG_LOADED, EXPERIMENT_SETTINGS_NON_SAMPLE_FILTER_UPDATE, EXPERIMENT_SETTINGS_SAMPLE_FILTER_UPDATE, EXPERIMENT_SETTINGS_PROCESSING_ERROR, EXPERIMENT_SETTINGS_SET_QC_STEP_ENABLED, EXPERIMENT_SETTINGS_COPY_SETTINGS_TO_ALL_SAMPLES, EXPERIMENT_SETTINGS_SET_SAMPLE_FILTER_SETTINGS_AUTO, EXPERIMENT_SETTINGS_ADD_CHANGED_QC_FILTER, EXPERIMENT_SETTINGS_QC_START, EXPERIMENT_SETTINGS_DISCARD_CHANGED_QC_FILTERS, EXPERIMENT_SETTINGS_UPDATE_SAMPLE_FROM_QC, EXPERIMENT_SETTINGS_PROCESSING_SAVE, EXPERIMENT_SETTINGS_PIPELINE_VERSION_UPDATED } from '../../actionTypes/experimentSettings';
import updateNonSampleFilterSettings from './processingConfig/updateNonSampleFilterSettings';
import updateSampleFilterSettings from './processingConfig/updateSampleFilterSettings';
import loadedProcessingConfig from './processingConfig/loadedProcessingConfig';
import processingSettingsError from './processingConfig/processingSettingsError';
import setQCStepEnabled from './processingConfig/setQCStepEnabled';
import copyFilterSettingsToAllSamples from './processingConfig/copyFilterSettingsToAllSamples';
import setSampleFilterSettingsAuto from './processingConfig/setSampleFilterSettingsAuto';
import addChangedQCFilter from './processingConfig/addChangedQCFilter';
import discardChangedQCFilters from './processingConfig/discardChangedQCFilters';
import updateSampleProcessingSettingsFromQC from './processingConfig/updateSampleProcessingSettingsFromQC';
import processingSettingsSaved from './processingConfig/processingSettingsSaved';
import updateExperimentInfo from './updateExperimentInfo';
import pipelineVersionUpdated from './pipelineVersionUpdated';
import pipelineStart from './pipelineStart';
var experimentSettingsReducer = function experimentSettingsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case EXPERIMENT_SETTINGS_INFO_UPDATE:
      {
        return updateExperimentInfo(state, action);
      }
    case EXPERIMENT_SETTINGS_PROCESSING_CONFIG_LOADED:
      {
        return loadedProcessingConfig(state, action);
      }
    case EXPERIMENT_SETTINGS_NON_SAMPLE_FILTER_UPDATE:
      {
        return updateNonSampleFilterSettings(state, action);
      }
    case EXPERIMENT_SETTINGS_SAMPLE_FILTER_UPDATE:
      {
        return updateSampleFilterSettings(state, action);
      }
    case EXPERIMENT_SETTINGS_UPDATE_SAMPLE_FROM_QC:
      {
        return updateSampleProcessingSettingsFromQC(state, action);
      }
    case EXPERIMENT_SETTINGS_PROCESSING_SAVE:
      {
        return processingSettingsSaved(state, action);
      }
    case EXPERIMENT_SETTINGS_PROCESSING_ERROR:
      {
        return processingSettingsError(state, action);
      }
    case EXPERIMENT_SETTINGS_SET_QC_STEP_ENABLED:
      {
        return setQCStepEnabled(state, action);
      }
    case EXPERIMENT_SETTINGS_COPY_SETTINGS_TO_ALL_SAMPLES:
      {
        return copyFilterSettingsToAllSamples(state, action);
      }
    case EXPERIMENT_SETTINGS_SET_SAMPLE_FILTER_SETTINGS_AUTO:
      {
        return setSampleFilterSettingsAuto(state, action);
      }
    case EXPERIMENT_SETTINGS_ADD_CHANGED_QC_FILTER:
      {
        return addChangedQCFilter(state, action);
      }
    case EXPERIMENT_SETTINGS_DISCARD_CHANGED_QC_FILTERS:
      {
        return discardChangedQCFilters(state, action);
      }
    case EXPERIMENT_SETTINGS_PIPELINE_VERSION_UPDATED:
      {
        return pipelineVersionUpdated(state, action);
      }
    case EXPERIMENT_SETTINGS_QC_START:
      {
        return pipelineStart(state, action);
      }
    case HYDRATE:
      {
        var _experimentInfo = action.payload.experimentSettings.info;
        if (_experimentInfo) {
          return _objectSpread(_objectSpread({}, state), {}, {
            info: _experimentInfo
          });
        }
        return state;
      }
    default:
      {
        return state;
      }
  }
};
export default experimentSettingsReducer;