import _isNil from "lodash/isNil";
import _union from "lodash/union";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
import getInitialState, { initialViewState } from 'redux/reducers/genes/getInitialState';
var downsampledGenesLoading = produce(function (draft, action) {
  var _action$payload = action.payload,
    ETag = _action$payload.ETag,
    genes = _action$payload.genes,
    componentUuid = _action$payload.componentUuid;
  if (ETag) {
    draft.expression.downsampled.ETag = ETag;
  }
  if (genes) {
    draft.expression.downsampled.loading = _union(original(draft).expression.downsampled.loading, genes);
  }

  // If the view hasn't stored properties yet, then set initial state
  if (_isNil(draft.expression.views[componentUuid])) {
    draft.expression.views[componentUuid] = initialViewState;
  }
  draft.expression.views[componentUuid].fetching = true;
  draft.expression.views[componentUuid].error = false;
}, getInitialState());
export default downsampledGenesLoading;