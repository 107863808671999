import _merge from "lodash/merge";
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'redux/reducers/cellSets/initialState';
var cellSetsUpdateProperty = produce(function (draft, action) {
  var _action$payload = action.payload,
    cellSetKey = _action$payload.cellSetKey,
    dataUpdated = _action$payload.dataUpdated;
  _merge(draft.properties[cellSetKey], dataUpdated);
}, initialState);
export default cellSetsUpdateProperty;