import { CELL_SETS_HIDE, CELL_SETS_UNHIDE } from '../../actionTypes/cellSets';
var setCellSetHiddenStatus = function setCellSetHiddenStatus(key) {
  return function (dispatch, getState) {
    if (getState().cellSets.hidden.has(key)) {
      dispatch({
        type: CELL_SETS_UNHIDE,
        payload: {
          key: key
        }
      });
    } else {
      dispatch({
        type: CELL_SETS_HIDE,
        payload: {
          key: key
        }
      });
    }
  };
};
export default setCellSetHiddenStatus;