import { UPDATE_LAYOUT } from 'redux/actionTypes/layout';
import initialState from './initialState';
import updateLayout from './updateLayout';
var layoutReducer = function layoutReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case UPDATE_LAYOUT:
      return updateLayout(state, action);
    default:
      return state;
  }
};
export default layoutReducer;