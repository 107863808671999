import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState, { initialEmbeddingState } from './initialState';
var embeddingsLoaded = produce(function (draft, action) {
  var _action$payload = action.payload,
    embeddingType = _action$payload.embeddingType,
    jsonData = _action$payload.data;
  draft[embeddingType] = _objectSpread(_objectSpread({}, initialEmbeddingState), {}, {
    loading: false
  });
  var data = [];
  jsonData.forEach(function (value, index) {
    if (value) {
      data[index] = value;
    }
  });
  draft[embeddingType].data = data;
}, initialState);
export default embeddingsLoaded;