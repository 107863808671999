import _remove from "lodash/remove";
/* eslint-disable no-param-reassign */
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import produce from 'immer';
var cellClassDelete = produce(function (draft, action) {
  var key = action.payload.key;

  // Remove cellClass from hierarchy
  var cellClassIndex = draft.hierarchy.findIndex(function (rootNode) {
    return rootNode.key === key;
  });
  var cellSetKeys = draft.hierarchy[cellClassIndex].children.map(function (_ref) {
    var cellSetKey = _ref.key;
    return cellSetKey;
  });

  // Remove cell clas from hierarchy
  draft.hierarchy.splice(cellClassIndex, 1);

  // Delete from the properties as well.
  delete draft.properties[key];
  cellSetKeys.forEach(function (cellSetKey) {
    // Delete cell sets under cell class
    delete draft.properties[cellSetKey];

    // Delete cell sets from hidden
    draft.hidden.delete(cellSetKey);
  });

  // Remove keys in selected cell sets
  _remove(draft.selected, function (currentSelectedKey) {
    return cellSetKeys.includes(currentSelectedKey);
  });
});
export default cellClassDelete;