/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from './initialState';
var backendStatusError = produce(function (draft, action) {
  var _action$payload = action.payload,
    experimentId = _action$payload.experimentId,
    error = _action$payload.error;
  draft[experimentId] = {
    status: {},
    loading: false,
    error: error
  };
}, initialState);
export default backendStatusError;