import { CONFIGS_REPLACED } from 'redux/actionTypes/componentConfig';
var replaceLoadedConfigs = function replaceLoadedConfigs(updatedConfigs) {
  return function (dispatch) {
    dispatch({
      type: CONFIGS_REPLACED,
      payload: {
        updatedConfigs: updatedConfigs
      }
    });
  };
};
export default replaceLoadedConfigs;