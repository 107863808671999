/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'redux/reducers/cellSets/initialState';
import { createHierarchyFromTree, createPropertiesFromTree } from 'redux/reducers/cellSets/helpers';
var cellSetsLoaded = produce(function (draft, action) {
  var data = action.payload.data;
  draft.loading = false;
  draft.hierarchy = createHierarchyFromTree(data);
  draft.properties = createPropertiesFromTree(data);
}, initialState);
export default cellSetsLoaded;