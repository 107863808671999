import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
var getSampleCells = function getSampleCells(cellSets, sampleKey) {
  if (!(sampleKey in cellSets.properties)) {
    return [];
  }
  var cellIds = Array.from(cellSets.properties[sampleKey].cellIds);
  return cellIds.map(function (cellId) {
    return {
      cellId: cellId,
      cellSetKey: sampleKey
    };
  });
};
export default getSampleCells;